import React, { useState, useEffect } from "react";
import $ from "jquery";
import Logo from "../images/logo.svg";
import MobileImg from "../images/mobile_menu_bg.svg";
import { NavLink } from "react-router-dom";
import usePersistedState from "../hooks/usePersistedState";
import ChangeLang from "./changeLang";
import MenuName from "./menuName";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, langSelector } from "../redux/slices/langSlice";
import detectBrowserLanguage from "detect-browser-language";


const Mainheader = () => {
  const dispatch = useDispatch();
  const selLang = useSelector(langSelector);

  const animationComplete = localStorage.getItem("bannerAnimation");
  const [show, setShow] = useState(0);
  const persistedLang = useSelector(langSelector);
  const [lastClickedMenuItem, setLastClickedMenuItem] = useState("");

  const [toggled, setToggled] = useState(false);
  const buttonClass = toggled ? "toggled" : "";

  useEffect(() => {
    /*if (persistedLang == null) {
        let lang = "";
        const browserLang = detectBrowserLanguage();
        if (browserLang == null || browserLang == "") {
            lang = "fr";
        } else {
            if (browserLang.includes("en")) {
                lang = "en";
            } else if (browserLang.includes("fr")) {
                lang = "fr";
            }
        }
        dispatch(changeLanguage(lang));
    }*/

    const timeout = setTimeout(
      () => {
        setShow(1);
      },
      animationComplete == true || animationComplete == "true" ? 0 : 4000
    );
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  $(() => {
    //On Scroll Functionality
    $(window).scroll(() => {
      var windowTop = $(window).scrollTop();
      windowTop > 30 ? $('#header_section').addClass('headersticky') : $('#header_section').removeClass('headersticky');
    });

    //Click Logo To Scroll To Top
    $("#logo").on("click", () => {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        500
      );
    });

    //Smooth Scrolling Using Navigation Menu

    $('a[href*="#"]').on("click", function (e) {
      // alert("Hi");
      $("html,body").animate(
        {
          scrollTop:
            $($(this).attr("href")).offset().top -
            60 /*Fixed header k liye is ko uncomment krna hai*/,
        },
        500
      );
      e.preventDefault();
    });

    //Toggle Menu
    /*$(".menu_icon").on("click", (evt) => {
 
      $(".menu_icon").toggleClass("closeMenu");
      $("#navigation_section").toggleClass("showMenu");
 
      $("#navigation_section li").on("click", (evt) => {
        
        $("#navigation_section").removeClass("showMenu");
        $(".menu_icon").removeClass("closeMenu");
      });
    });*/
  });

  const hamburgerMenuClicked = () => {
    $('.menu_icon').toggleClass('closeMenu');
    $('#navigation_section ul').toggleClass('showMenu');

    $('#navigation_section li').on('click', () => {
      $('#navigation_section ul').removeClass('showMenu');
      $('.menu_icon').removeClass('closeMenu');
    });
  };

  return (
    <div>
      {/*HEADER_SECTION_START*/}
      {show === 1 && (
        <header id="header_section" className={`hm_header ${show === 1 ? 'lottie1' : ''} `}>
          <div className="page_width">
            <div className="headerinner">
              <div className="menu_icon">
                <div id="menu-icon" onClick={hamburgerMenuClicked}>
                  <div className="Mbarline" />
                  <div className="Mbarline" />
                  <div className="Mbarline" />
                </div>
              </div>
              <div id="logo"> <NavLink exact to={`${selLang == 'fr' ? '/fr/' : '/'}`} onClick={scrollToTop}>
                <img src={Logo} alt="logo" />
              </NavLink></div>
              <MenuName />
              <ChangeLang />
            </div>
          </div>
        </header>


        //   <header
        //       id="header_section"
        //       className="hm_header home_header"
        //       style={{ background: "#fff" }}
        //   >
        //       <div className="page_container">
        //           <div className="headerinner">
        //               <div className="menu_icon">
        //                   <div id="menu-icon" onClick={hamburgerMenuClicked}>
        //                       <div className="Mbarline" />
        //                       <div className="Mbarline" />
        //                       <div className="Mbarline" />
        //                   </div>
        //               </div>
        //               <div id="logo">
        //                   <NavLink exact to={`${selLang == 'fr' ? '/fr/' : '/'}`} onClick={scrollToTop}>
        //                       <img src={Logo} alt="logo" />
        //                   </NavLink>
        //               </div>
        //               <nav
        //                   id="navigation_section"
        //                   data-aos="fade-down"
        //                   data-aos-duration="1000"
        //               >
        //                   <MenuName />

        //                   {/* <div className="navigation_inner">
        //     <ul>
        //       <li>
        //         <NavLink
        //           activeClassName="active_menu"
        //           to="/propos"
        //           onClick={scrollToTop}
        //         >
        //           A PROPOS D’AYDO
        //         </NavLink>
        //       </li>
        //       <li>
        //         <NavLink
        //           activeClassName="active_menu"
        //           to="/nssolution"
        //           onClick={scrollToTop}
        //         >
        //           NOS SOLUTIONS
        //         </NavLink>
        //       </li>
        //       <li>
        //         <NavLink
        //           activeClassName="active_menu"
        //           to="/shapepage"
        //           onClick={scrollToTop}
        //         >
        //           <div className="menu_shape" />
        //         </NavLink>
        //       </li>
        //       <li>
        //         <NavLink
        //           activeClassName="active_menu"
        //           to="/nsengamement"
        //           onClick={scrollToTop}
        //         >
        //           Nos Engagements
        //         </NavLink>
        //       </li>
        //       <li>
        //         <NavLink
        //           activeClassName="active_menu"
        //           to="/joinus"
        //           onClick={scrollToTop}
        //         >
        //           NOUS REJOINDRE
        //         </NavLink>
        //       </li>
        //       <li>
        //         <NavLink
        //           activeClassName="active_menu"
        //           to="/contact"
        //           onClick={scrollToTop}
        //         >
        //           Contact
        //         </NavLink>
        //       </li>
        //     </ul>
        //     <ChangeLang />
        //   </div>
        //  */}
        //               </nav>
        //           </div>
        //       </div>
        //   </header>


      )}

      {/* </header> */}
      {/*HEADER_SECTION_END*/}
    </div>
  );
};

export default Mainheader;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import { langSelector } from "../../redux/slices/langSlice";
import useApiClient from "../../hooks/useApiClient";
import parse from "html-react-parser";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import RequestImg from '../../images/join_bg.webp'
import FormImg from '../../images/form_contact_bg.webp'
import RequestForm from "./requestForm";


const RequestSent = () => {
    const selLang = useSelector(langSelector);

    const [loginData, setLoginData] = useState(null)

    const [makeApiCall] = useApiClient();

    useEffect(async () => {
        let response = await makeApiCall("get_site_contents&page_id=40");
        setLoginData(response);
    }, [selLang]);

    return (
        <div>
            <Header />
            <section id="content_section">
                <div className="request_sent_page">
                    <div className="gerenric_form_content gerenric_padding">
                        <div className="fc_top_bg"><img src={RequestImg} alt="" /></div>
                        <div className="fc_bg"><img src={FormImg} alt="" /></div>
                        <div className="page_width">
                            <div className="gerenric_form_content_inner align_center">
                                <div className="fc_left">

                                    <div className="gerenric_heading hdg_white">
                                        {loginData?.data &&
                                            parse(`${loginData?.data?.req_access_v2_details}`)}</div>
                                </div>
                                <div className="fc_right">
                                    <RequestForm requestData={loginData?.data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default RequestSent
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { langSelector } from "../../redux/slices/langSlice";
import useApiClient from "../../hooks/useApiClient";
import parse from "html-react-parser";
import PrivacyImg from '../../images/gerenric_content_bg.svg'

const PrivacyContent = () => {
  const dispatch = useDispatch();
  const [privacyData, setPrivacyData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=30");
    setPrivacyData(response);
  }, [selLang]);

  return (
    <div>

      <section id="content_section">
        <div className="privacy_policy_page gerenric_padding position_relative">
          <div className="gerenric_content_bg"><img src={PrivacyImg} alt="" /></div>
          <div className="page_width">
            <div className="gerenric_content">
              <div className="content_block">
                <div className="gerenric_heading"><span className="hdg_border_none">{privacyData.data && parse(`${privacyData?.data?.privacy_head}`)}
                </span></div>
              </div>
              {privacyData.data && parse(`${privacyData?.data?.privacy_details}`)}

              {/* <div className="content_block">
                <div className="gerenric_heading"><span className="hdg_border_none">Privacy Policy</span></div>
                <div className="date_text">Last update: 22/12/2021</div>
                <p>This privacy policy informs you of the processing of personal data carried out when you use the website available at the following URL address: <a href="https://www.aydofinance.com/" className="link_underline">https://www.aydofinance.com/</a> (the "Website").</p>
                <p>AYDO is an investment firm that assists professional investors in their alternative investment projects, in particular through the provision of a dedicated platform.</p>
                <p>If you wish to obtain information about the use of cookies, you can read the Cookies Policy.</p>
              </div>
              <div className="content_block">
                <h2>Table of Contents</h2>
                <ol>
                  <li><a href="javascript:void(0)">Who is responsible for the personal data processing carried out via the Website?</a></li>
                  <li><a href="javascript:void(0)">What categories of personal data are processed via the Website?</a></li>
                  <li><a href="javascript:void(0)">On what legal basis and for what purposes are your personal data processed?</a></li>
                  <li><a href="javascript:void(0)">Who are the data recipients of your personal data collected via the Website?</a></li>
                  <li><a href="javascript:void(0)">Transfer of your personal data outside the European Economic Area (EEA): why and how??</a></li>
                  <li><a href="javascript:void(0)">What are your rights regarding your personal data?</a></li>
                  <li><a href="javascript:void(0)">How long is your personal data kept?</a></li>
                  <li><a href="javascript:void(0)">How can you contact us to exercise your rights and for any questions?</a></li>
                  <li><a href="javascript:void(0)">How can this Policy be modified?</a></li>
                </ol>
              </div>
              <div className="content_block">
                <h2>1) Who is responsible for the personal data processing carried out via the Website? </h2>
                <p><span>Subtitle example</span></p>
                <p>The Website is published by AYDO, a limited liability company (“société par actions simplifiée”) subject to French law (SAS), having its registered office at 42 rue Notre Dame des Victoires, 75002 Paris, France, registered at the Commercial and Companies Register of Paris under the reference number 808 533 954 ("AYDO"). AYDO is the "data controller" within the meaning of the applicable legislation relating to personal data protection.</p>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/*CONTENT_SECTION_START*/}
      {/* <section id="content_section">
        <div className="inner_heading h_mb_80">
          <br />
          <h1> {privacyData.data && privacyData?.data?.privacy_head}</h1>
          <br />
          <br />
        </div>
        <div className="legal_content">
          <div className="all_content">
            {privacyData.data && parse(`${privacyData?.data?.privacy_details}`)}
          </div>
        </div>
      </section> */}
      {/*CONTENT_SECTION_END*/}
    </div>
  );
};

export default PrivacyContent;

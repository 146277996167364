import React, { useState, useEffect } from "react";
import $ from "jquery";
import Logo from "../images/logo.svg";
import MobileImg from "../images/mobile_menu_bg.svg";
import { NavLink } from "react-router-dom";
import MenuName from "./menuName";
import ChangeLang from "./changeLang";
import { useSelector } from "react-redux";
import { langSelector } from "../redux/slices/langSlice";

const Mainheader = () => {

  const selLang = useSelector(langSelector);

  const [currentMenuItem, setCurrentMenuItem] = useState('');

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  $(() => {
    //On Scroll Functionality
    $(window).scroll(() => {
      var windowTop = $(window).scrollTop();
      windowTop > 30 ? $('#header_section').addClass('headersticky') : $('#header_section').removeClass('headersticky');
    });

    //Click Logo To Scroll To Top
    $("#logo").on("click", () => {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        500
      );
    });

    //Smooth Scrolling Using Navigation Menu

    $('a[href*="#"]').on("click", function (e) {
      $("html,body").animate(
        {
          scrollTop:
            $($(this).attr("href")).offset().top -
            60 /*Fixed header k liye is ko uncomment krna hai*/,
        },
        500
      );
      e.preventDefault();
    });



  });

  const hamburgerMenuClicked = () => {
    $('.menu_icon').toggleClass('closeMenu');
    $('#navigation_section ul').toggleClass('showMenu');

    $('#navigation_section li').on('click', () => {
      $('#navigation_section ul').removeClass('showMenu');
      $('.menu_icon').removeClass('closeMenu');
    });
  }

  return (
    <div>

      <header id="header_section">
        <div className="page_width">
          <div className="headerinner">
            <div className="menu_icon">
              <div id="menu-icon" onClick={hamburgerMenuClicked}>
                <div className="Mbarline" />
                <div className="Mbarline" />
                <div className="Mbarline" />
              </div>
            </div>
            <div id="logo"> <NavLink exact to={`${selLang == 'fr' ? '/fr/' : '/'}`} onClick={scrollToTop}>
              <img src={Logo} alt="logo" />
            </NavLink></div>
            <MenuName />
            <ChangeLang />
          </div>
        </div>
      </header>

    </div>
  );
};

export default Mainheader;

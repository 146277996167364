import React from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import PrivacyContent from "./privacyPolicy";
const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <PrivacyContent />
      <br />
      <br />
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;

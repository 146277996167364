import React, { useState, useEffect } from "react";
import CompanyLogo1 from "../../../assests/images/regulee_logo1.png";
import CompanyLogo2 from "../../../assests/images/regulee_logo2.png";
import CompanyLogo3 from "../../../assests/images/regulee_logo3.png";
import CompanyLogo4 from "../../../assests/images/regulee_logo_mobile.png";
import EcoLogo from "../../../assests/images/ecosystem_logo1.png";
import EcoLogo1 from "../../../assests/images/AFTE_logo_carré_bleu.png";
import EcoLogo2 from "../../../assests/images/finch_logo_two.png";
import EngLogo from "../../../assests/images/engagee_logo1.png";
import MainLogo from "../../../assests/images/nos engagements.png";
import MainLogoDesk from "../../../assests/images/engaiment.png";
import Regulation from "../../../images/regulation_image_d.webp";
import RegulationM from "../../../images/regulation_image_m.png";
import RegulationLogoOne from "../../../images/regulation_logo1.png";
import RegulationLogoTwo from "../../../images/regulation_logo2.png";
import RegulationLogoThree from "../../../images/regulation_logo3.png";
import Innovation from "../../../images/innovation_image_d.png";
import InnovationM from "../../../images/innovation_image_m.png";
import InnovationLogoOne from "../../../images/innovation_logo1.png";
import InnovationLogoTwo from "../../../images/innovation_logo2.png";
import InnovationLogoThree from "../../../images/innovation_logo3.png";
import Inclusion from "../../../images/inclusion_image_d.webp";
import InclusionM from "../../../images/inclusion_image_m.png";
import InclusionLogo from "../../../images/inclusion_logo.png";
import FranceDigitale from '../../../images/france_digitale.webp'



import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { langSelector } from "../../../redux/slices/langSlice";
import useApiClient from "../../../hooks/useApiClient";

const ContenctMangement = () => {
  const [engagementData, setEngagementData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=24");
    setEngagementData(response);
  }, [selLang]);

  console.log("New Engagment Data", engagementData)

  return (
    <div>

      <section id="content_section">
        <div className="commitment_page gerenric_padding ">
          <div className="page_width">
            <div className="gerenric_heading">
              {engagementData.data &&
                parse(`${engagementData?.data?.commitments_v2_head}`)}
            </div>
            <div className="commitment_block">
              <div className="commitment_left">
                <div className="commitment_image"><img src={Regulation} alt="" className="cm_desktop" /> <img src={RegulationM} alt="" className="cm_mobile" /></div>
                <div className="commitment_title"> {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_v2_sec1_head}`)}</div>
              </div>
              <div className="commitment_right">
                {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_v2_sec1_details}`)}
                <ul>
                  <li><img src={RegulationLogoOne} alt="" /></li>
                  <li><img src={RegulationLogoTwo} alt="" /></li>
                  <li><img src={RegulationLogoThree} alt="" /></li>
                </ul>
              </div>
            </div>
            <div className="commitment_block">
              <div className="commitment_left">
                <div className="commitment_image"><img src={Innovation} alt="" className="cm_desktop" /> <img src={InnovationM} alt="" className="cm_mobile" /></div>
                <div className="commitment_title"> {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_v2_sec2_head}`)}</div>
              </div>
              <div className="commitment_right">
                {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_v2_sec2_details}`)}
                <ul>
                  <li><img src={InnovationLogoOne} alt="" /></li>
                  <li><img src={InnovationLogoTwo} alt="" /></li>
                  <li><img src={InnovationLogoThree} alt="" /></li>
                </ul>
                <div className="new_innovation_img">
                  <img src={FranceDigitale} width="200px" style={{ marginTop: '1rem', margin: 'auto' }} alt="" />
                </div>
              </div>
            </div>
            <div className="commitment_block">
              <div className="commitment_left">
                <div className="commitment_image"><img src={Inclusion} alt="" className="cm_desktop" /> <img src={InclusionM} alt="" className="cm_mobile" /></div>
                <div className="commitment_title"> {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_v2_sec3_head}`)}</div>
              </div>
              <div className="commitment_right">
                {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_v2_sec3_details}`)}
                <ul>
                  <li><img src={InclusionLogo} alt="" /></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/*CONTENT_SECTION_START*/}
      {/* <section id="content_section">
        <div className="inner_heading h_mb_80">
          <h1 className="inner_heading_cricle">
            {engagementData.data &&
              parse(`${engagementData?.data?.commitments_head}`)}
          </h1>
        </div>
        <div className="engagements_page">
          <div className="engagements_section regulee_section">
            <div className="left_side">
              <h3>
                {" "}
                {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_sec1_head}`)}
              </h3>
              <div className="engagements_logo">
                <ul>
                  <li className="engagement_desktop_logo">
                    <img src={MainLogoDesk} alt="" />
                  </li>
                  <li className="engagement_mobile_logo">
                    <img src={MainLogoDesk} alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="right_side">
              {engagementData.data &&
                parse(`${engagementData?.data?.commitments_sec1_details}`)}
            </div>
            <div
              className="regulee_image_bg wow slideInLeft"
              data-wow-duration="1s"
              data-aos="fade-right"
              data-aos-duration="1000"
            />
            <div className="clearfix" />
          </div>
          <div className="new_management_section engagements_section ecosystem_section engagement_cell_modification">
            <div className="inner_data">
              <h3>
                {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_sec2_head}`)}{" "}
              </h3>
            </div>

            <div className="inner_data">
              <div className="">
                <div className="engagements_logo engagements_logo_1">
                  <ul className="engagament_logo_data">
                    <li>
                      <a href="https://francefintech.org/" target="_blank">
                        <img src={EcoLogo} alt="" className="finch_logo" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.laplace-fintech.com/" target="_blank">
                        <img src={EcoLogo2} alt="" width="168px" />
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
              <div className="">
                <div className=" ">
                  {engagementData.data &&
                    parse(`${engagementData?.data?.commitments_sec2_details}`)}{" "}
                </div>
              </div>

            </div>
            <div className="inner_data">
              <div className="">
                <div className="engagements_logo engagements_logo_1">
                  <ul>
                    <li className="new_logo_links">
                      <a href="https://www.afte.com/" target="_blank">
                        <img src={EcoLogo1} alt="" className="new_logo_image" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="">
                <div style={{ marginTop: '10px' }}>
                  {engagementData.data &&
                    parse(`${engagementData?.data?.commitments_sec2_details_2}`)}{" "}
                </div>
              </div>
              <div
                className="ecosystem_image_bg wow slideInLeft"
                data-wow-duration="1s"
                data-aos="fade-right"
                data-aos-duration="1000"
              />

            </div>

          </div>
          <div className="engagements_section engagee_section third_section">
            <div className="">
              <div className="left_side">
                <h3>
                  {engagementData.data &&
                    parse(`${engagementData?.data?.commitments_sec3_head}`)}
                </h3>
                <div className="engagements_logo">
                  <ul>
                    <li>
                      <a
                        href="http://www.lesentreprises-sengagent.gouv.fr/"
                        target="_blank"
                      >
                        <img src={EngLogo} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right_side">
                {engagementData.data &&
                  parse(`${engagementData?.data?.commitments_sec3_details}`)}
              </div>
              <div
                className="engagee_image_bg wow slideInRight"
                data-wow-duration="1s"
                data-aos="fade-left"
                data-aos-duration="1000"
              />
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </section> */}
      {/*CONTENT_SECTION_END*/}
    </div>
  );
};

export default ContenctMangement;

import React from "react";
import Mainheader from "../../layout/Mainheader";
import Footer from "../../layout/Footer";
import ShapeContent from "./components/shapeContent";
import Header from "../../layout/Header";
const Shapepage = () => {
  return (
    <div>
      <Header />
      <section id="content_section">
        <ShapeContent />
      </section>
      <Footer />
    </div>
  );
};

export default Shapepage;

import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import Homepage from "./pages/homepage";
import Propos from "./pages/propos";
import NsSolution from "./pages/ns-solution";
import Shapepage from "./pages/shape";
import NsManagement from "./pages/ns-management";
import Joinus from "./pages/joinus";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacypolicy";
import Compliance from "./pages/compliance";
import CookiePolicy from "./pages/cookiePolicy";
import Legal from "./pages/legal";
import RequestSent from "./pages/requestSent";
import Login from "./pages/auth/login";
import { langSelector } from "./redux/slices/langSlice";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import useApiClient from "./hooks/useApiClient";
import { changeLanguage } from "./redux/slices/langSlice";
import "./animation.scss";
import Aos from "aos";

function App() {
  const [menuData, setMenuData] = useState([]);
  const [makeApiCall] = useApiClient();
  const selLang = useSelector(state=>langSelector(state));
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(async () => {
    const location = window.location.pathname;
    

    if(location.includes('fr')){
      if(selLang!='fr'){
        dispatch(changeLanguage("fr"));
        history.push(location);
      }
    }
    else {
      if(selLang=='fr'){
        history.replace("/fr" + location);
      }
    }






    let response = await makeApiCall("get_site_contents&page_id=18");
    setMenuData(response);
    Aos.init({ duration: 2000 });
  }, [selLang]);

 
  const articleStructuredData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    url: "https://www.aydofinance.com",
    logo: "https://aydofinance.com/images/cslogo.svg",
    name: "AYDO",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+33189203890",
      contactType: "Customer service",
      availableLanguage: ["en", "fr", "Portuguese"],
    },
    sameAs: "https://www.linkedin.com/company/weareaydo",
  };

  return (
    <div id="container" align="center">
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/fr" component={Homepage} />

        <Route exact path="/about" component={Propos} />
        <Route exact path="/fr/about" component={Propos} />

        <Route exact path="/solutions" component={NsSolution} />
        <Route exact path="/fr/solutions" component={NsSolution} />

        <Route exact path="/about-shape" component={Shapepage} />
        <Route exact path="/fr/about-shape" component={Shapepage} />

        <Route exact path="/commitments" component={NsManagement} />
        <Route exact path="/fr/commitments" component={NsManagement} />

        <Route exact path="/career" component={Joinus} />
        <Route exact path="/fr/career" component={Joinus} />

        <Route exact path="/contact" component={Contact} />
        <Route exact path="/fr/contact" component={Contact} />

        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/fr/privacypolicy" component={PrivacyPolicy} />

        <Route exact path="/compliance" component={Compliance} />
        <Route exact path="/fr/compliance" component={Compliance} />

        <Route exact path="/cookiepolicy" component={CookiePolicy} />
        <Route exact path="/fr/cookiepolicy" component={CookiePolicy} />

        <Route exact path="/legal" component={Legal} />
        <Route exact path="/fr/legal" component={Legal} />

        <Route exact path="/request_sent" component={RequestSent} />
        <Route exact path="/fr/request_sent" component={RequestSent} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/fr/login" component={Login} />
      </Switch>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Mainheader from "../../layout/Mainheader";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { langSelector } from "../../redux/slices/langSlice";
import { useSelector } from "react-redux";
import useApiClient from "../../hooks/useApiClient";
import parse from "html-react-parser";
import $ from "jquery";
import ContactContent from "./components/contactPage";

const Contact = () => {
 
  return (
    <div>
      <Header />
      <section id="content_section">
        <ContactContent />
      </section>
      <Footer />
    </div>
  );
};

export default Contact;

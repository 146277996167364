import React, { useState, useEffect } from "react";
// import Abbolsion from "../../../assests/images/abolissons_image.svg";
// import Penson from "../../../assests/images/colors.webp";
// import Footer from "../../../assests/images/footerlogo.svg";
// import DevelopImg from "../../../assests/images/Mask-Group.png";
// import RotateImg from "../../../assests/images/Rectangle_5.png";
import WhatWe from '../../../images/what_we_image.webp'
import LogoShape from '../../../images/shape_logo.svg'
import BlShape from '../../../images/shape_bl_big_image.webp'
import BlShapeOne from '../../../images/shape_bl_image1.webp'
import BlShapeTwo from '../../../images/shape_bl_image2.webp'
import Eduction from '../../../images/education_image.webp'

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { langSelector } from "../../../redux/slices/langSlice";
import useApiClient from "../../../hooks/useApiClient";
import parse from "html-react-parser";

const Content = () => {
  const dispatch = useDispatch();
  const [homeData, setHomeData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=19");
    setHomeData(response);
  }, [selLang]);

  var position = $(window).scrollTop();
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > position) {
      $(".pensons_circle").css("width", "390px");
      $(".pensons_circle").css("height", "420px");
      $(".pensons_img").css("transform", "scale(1.2)");
      $(".develop_img").css("transform", "scale(1.8)");
      $(".rotate-img").addClass("rotate-img-animation");
    } else {
      $(".pensons_circle").css("width", "380px");
      $(".pensons_circle").css("height", "380px");
      $(".pensons_img").css("transform", "scale(1)");
      $(".develop_img").css("transform", "scale(1)");
      // $(".rotate-img").removeClass("rotate-img-animation");
    }
    position = scroll;
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {/* CONTENT_SECTION_START */}
      <div id="section_1"></div>
      <section id="content_section">
        <div className="our_mission_section gerenric_padding" id="downsection">
          <div className="page_width">
            <div className="our_mission_inner">
              <div className="gerenric_heading"><span>{homeData?.data?.home_v2_sec1_head}</span></div>
              {homeData?.data && parse(`${homeData?.data?.home_v2_sec1_details}`)}
            </div>
          </div>
        </div>
        <div className="what_we_section gerenric_padding">
          <div className="what_we_inner">
            <div className="page_width">
              <div className="what_we_image"><img src={WhatWe} alt="" /></div>
              <div className="what_we_detail">
                <div className="gerenric_heading"><span>{homeData?.data?.home_v2_sec2_head}</span></div>
                {homeData?.data && parse(`${homeData?.data?.home_v2_sec2_details}`)}
                <div className="full_width"><Link to={selLang == 'fr' ? '/fr/solutions' : '/solutions'}><div className="gerenric_button">{homeData?.data?.home_v2_sec2_btn1}</div></Link></div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape_section gerenric_padding">
          <div className="page_width">
            <div className="shape_block_1">
              <div className="shape_block_inner">
                <div className="shape_detail">
                  <div className="shape_logo"><img src={LogoShape} alt="" /></div>
                  {homeData?.data && parse(`${homeData?.data?.home_v2_sec3_details1}`)}
                  {/* <h2>An innovative, data-driven platform for unparalleled management, reporting, and analytics of structured products.</h2> */}
                </div>
                <div className="shape_image"><img src={BlShape} alt="" /></div>
              </div>
            </div>
            {/* <div className="shape_block_2">
              <div className="shape_col">
                <div className="shape_item">
                  <div className="shape_item_inner">
                    <div className="shape_image"><img src={BlShapeOne} alt="" /></div>
                    {homeData?.data && parse(`${homeData?.data?.home_v2_sec3_details2}`)}
                  </div>
                </div>
              </div>
              <div className="shape_col">
                <div className="shape_item">
                  <div className="shape_item_inner">
                    <div className="shape_image"><img src={BlShapeTwo} alt="" /></div>
                    {homeData?.data && parse(`${homeData?.data?.home_v2_sec3_details3}`)}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="education_section gerenric_padding">
          <div className="page_width">
            <div className="education_inner">
              <div className="education_image"><img src={Eduction} alt="" /></div>
              <div className="education_detail">
                <div className="gerenric_heading"><span> {homeData?.data?.home_v2_sec4_head}</span></div>
                {homeData?.data && parse(`${homeData?.data?.home_v2_sec4_details}`)}

              </div>
            </div>
          </div>
        </div>
        <div className="sturctured_product_section gerenric_padding">
          <div className="page_width">
            <div className="sturctured_product_inner">
              {homeData?.data && parse(`${homeData?.data?.home_v2_sec5_details}`)}
              <div className="full_width txt_align_center"><Link to={selLang == 'fr' ? '/fr/contact' : '/contact'} onClick={scrollToTop} ><div className="gerenric_button bg_blue">{homeData?.data?.home_v2_sec5_btn}</div></Link></div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="content_section">
        <div className="hm_non_abolissons_section">
          <div className="left_side">
            <div className="abolissons_image">
              <div className="" data-aos="zoom-in" data-aos-duration="3000">
                <img src={Abbolsion} alt="" />
              </div>
            </div>
          </div>
          <div className="right_side">
            <div
              className="abolissons_detail"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <div>
                {homeData.data && <h3>{homeData.data.home_sec1_head} </h3>}
                {homeData.data && <p>{homeData.data.home_sec1_details} </p>}
              </div>
            </div>
          </div>
        </div>
        <div className="hm_non_developpons_section">
          <div className="develop_side_image">
            <img src={DevelopImg} className="develop_img" alt="" />
          </div>

          <img src={RotateImg} className="rotate-img" alt="" />
          <div className="blue_box"></div>

          <div className="hm_non_developpons_inner">
            {homeData.data && <h3>{homeData.data.home_sec2_head} </h3>}
            {homeData.data && <p>{homeData.data.home_sec2_details} </p>}
            <div className="clearfix" />
          </div>
        </div>
        <div className="hm_non_pensons_section">
          <div className="left_side">
            <div className="pensons_detail">
              <div data-aos="fade-up" data-aos-duration="500">
                {homeData.data && <h3>{homeData.data.home_sec3_head} </h3>}
                {homeData.data && <p>{homeData.data.home_sec3_details} </p>}
              </div>
            </div>
          </div>
          <div className="right_side">
            <div className="pensons_image">
              <img src={Penson} alt="" className="pensons_img" />
              <div className="pensons_circle" />
            </div>
          </div>
        </div>
        <div className="hm_creative_section">
          <div
            className="left_img"
            data-aos="fade-right"
            data-aos-duration="3000"
          ></div>
          <div
            className="right_img"
            data-aos="fade-left"
            data-aos-duration="3000"
          ></div>
          <div className="hm_creative_inner">
            <div className="creative_logo">
              <img src={Footer} alt="" data-aos="zoom-in" />
            </div>
            {homeData.data && (
              <h3 data-aos="zoom-in">{homeData.data.home_sec4_head} </h3>
            )}

            <div className="full_width txt_align_center">
              <Link to={`${selLang == "fr" ? "/fr/contact" : "/contact"}`}>
                {homeData.data && (
                  <div className="gerenric_button">
                    {homeData.data.home_sec4_btn_lbl}
                  </div>
                )}
              </Link>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Content;

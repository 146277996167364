import { createSlice } from '@reduxjs/toolkit';
import detectBrowserLanguage from 'detect-browser-language';

// const getInitialState=()=>{
//     const browserLang = detectBrowserLanguage();
//     if(browserLang.includes('en')){
//         return {selectedLang : 'en'};
//     }
//     else if(browserLang.includes('fr')){
//         return {selectedLang: 'fr'};
//     }
//     return {selectedLang: 'fr'};
// }


const langSlice = createSlice({
    name: 'language',
    initialState: {selectedLang: ''},//getInitialState(),
    reducers: {
        changeLanguage(state, action) {
            state.selectedLang = action.payload;
        },
    },

})

export const { changeLanguage } = langSlice.actions;
export default langSlice.reducer;

export const langSelector = (state) => {
    if(state.language.selectedLang==''){
        const browserLang = detectBrowserLanguage();
        if(browserLang.includes('en')){
            return 'en';
        }
        else if(browserLang.includes('fr')){
            return 'fr';
        }
        return 'fr';
    }
    return state.language.selectedLang;
}


import React, { useState, useEffect } from "react";
// import ShapeIcon from "../../../assests/images/shape_icon.svg";
// import Sideimage from "../../../assests/images/inner_sideimage.svg";
// import Shapeicon2 from "../../../assests/images/shape_service_icon1.svg";
// import Shapeicon3 from "../../../assests/images/shape_service_icon2.svg";
// import Shapeicon4 from "../../../assests/images/shape_service_icon3.svg";
// import Shapeicon5 from "../../../assests/images/shape_service_icon4.svg";
// import BlockIcon1 from "../../../assests/images/icon1.svg";
// import BlockIcon2 from "../../../assests/images/icon2.svg";
// import BlockIcon3 from "../../../assests/images/icon3.svg";
// import BlockIconNew from "../../../assests/images/Icon1_new.svg";
// import Logo from "../../../assests/images/shape_logo_white.svg";
// import Arrow from "../../../assests/images/according_arrowdown.svg";
import ShapeBg from "../../../images/shage_pg_image.webp";
import ShapeBgBlur from "../../../images/shape_bl_image1.webp";
import ShapeBgBlurTwo from "../../../images/shape_bl_image2.webp";


import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { langSelector } from "../../../redux/slices/langSlice";
import useApiClient from "../../../hooks/useApiClient";
import $ from "jquery";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ReactPlayer from 'react-player'

// Demo styles, see 'Styles' section below for some notes on use.
import "../../../assests/css/fancy-example.scss";

const ShapeContent = () => {
  const [shapeData, setShapeData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=21");
    setShapeData(response);
  }, [selLang]);

  /* $(document).ready(function () {
        if ($(window).width() <= 1024) {
            /*$(document).ready(function () {
                $(".grnc_accordion_head").click(function () {
                    if ($(".grnc_accordion_body").is(":visible")) {
                        $(".grnc_accordion_body").slideUp(300);
                        $(".grnc_arrow_up_down").html(
                            '<img src="images/according_arrowdown.svg" alt="">'
                        );
                    }
                    if ($(this).next(".grnc_accordion_body").is(":visible")) {
                        $(this).next(".grnc_accordion_body").slideUp(300);
                        $(this)
                            .children(".grnc_arrow_up_down")
                            .html('<img src="images/according_arrowdown.svg" alt="">');
                    } else {
                        $(this).next(".grnc_accordion_body").slideDown(300);
                        $(this)
                            .children(".grnc_arrow_up_down")
                            .html('<img src="images/according_arrowdown.svg" alt="">');
                    }
                });
            });
        }
    });*/

  return (
    <div>

      <section id="content_section">
        <div className="shape_page gerenric_padding">
          <div className="shape_heading">
            <div className="page_width">
              <div className="gerenric_heading hdg_white">
                {shapeData.data && parse(`${shapeData?.data?.shape_v2_head}`)}
              </div>
            </div>
          </div>
          <div className="shape_main_image shape_section">
            <div className="page_width">
              <div className="">
                <div className="">
                  <ReactPlayer url='https://docs-vault2.s3.eu-west-1.amazonaws.com/videos/Aydo_demo.mp4' width="100%" height="500px" playing={true} loop={true} />
                </div>
              </div>
            </div>

            {/* <img src={ShapeBg} alt="" /> */}
          </div>
          <div className="gerenric_padding shape_buttons">
            <div className="shape_inner_btn txt_align_center mb_20"><Link to={selLang == 'fr' ? '/fr/request_sent' : '/request_sent'}><div className="gerenric_button">{shapeData?.data?.shape_v2_request_btn}</div></Link></div>
            <div className="shape_inner_btn txt_align_center"><Link to={selLang == 'fr' ? '/fr/login' : '/login'}><div className="gerenric_button bg_blue">{shapeData?.data?.shape_v2_login_btn}</div></Link></div>

          </div>
          {/* <div className="shape_section">
            <div className="page_width">
              <div className="shape_block_2">
                <div className="shape_col">
                  <div className="shape_item">
                    <div className="shape_item_inner">
                      <div className="shape_image"><img src={ShapeBgBlur} alt="" /></div>
                      {shapeData.data && parse(`${shapeData?.data?.shape_v2_sec1_detail1}`)}
                    </div>
                  </div>
                </div>
                <div className="shape_col">
                  <div className="shape_item">
                    <div className="shape_item_inner">
                      <div className="shape_image"><img src={ShapeBgBlurTwo} alt="" /></div>
                      {shapeData.data && parse(`${shapeData?.data?.shape_v2_sec1_detail2}`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>



      {/* <div className="inner_heading h_mb_80">
        <h1 className="bluetextcolor">
          {shapeData.data && parse(`${shapeData?.data?.shape_head}`)}
        </h1>
      </div>
      <div className="shape_page">
        <div className="shape_section1">
          <div className="shaperight_image">
            <img src={Sideimage} alt="" />
          </div>
          <div className="shape_paragraph">
            {shapeData.data && parse(`${shapeData?.data?.shape_details}`)}
          </div>
          <div className="shape_service">
            <div className="shape_col wow fadeInUp" data-aos="fade-up">
              <div className="shape_item">
                <div className="shape_icon">
                  <img src={BlockIconNew} alt="" />
                </div>
                {shapeData.data &&
                  parse(`${shapeData?.data?.shape_subdetails1}`)}
              </div>
            </div>
            <div className="shape_col  " data-aos="fade-up">
              <div className="shape_item">
                <div className="shape_icon">
                  <img src={BlockIcon1} alt="" />
                </div>
                {shapeData.data &&
                  parse(`${shapeData?.data?.shape_subdetails2}`)}
              </div>
            </div>
            <div
              className="shape_col wow fadeInUp"
              data-aos="fade-up"
              data-wow-duration="2s"
            >
              <div className="shape_item">
                <div className="shape_icon">
                  <img src={BlockIcon3} alt="" />
                </div>
                {shapeData.data &&
                  parse(`${shapeData?.data?.shape_subdetails3}`)}
              </div>
            </div>
           
          </div>
        </div>
        <div
          className="shape_section2 wow fadeInUp"
          data-aos="fade-up"
          data-wow-duration="2s"
        >
          <div className="shape_section2_inner">
            <div className="shape_logo">
              <img src={Logo} alt="" />
            </div>
            <div className="shape_row for_sticky">
              <div className="shape_row_inner">
                <div className="shape_left for_sticky">
                  <h2>Client app</h2>
                </div>
                <div className="shape_right">
                  <h2 className="bluecolor">Team app</h2>
                </div>
              </div>
            </div>
            <div>
              <Accordion preExpanded={["a"]}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="grnc_accordion_head">
                        <div className="grnc_accordion_head_inner">
                          {shapeData.data &&
                            parse(`${shapeData?.data?.shape_sub1_head}`)}
                          <img src={Arrow} />
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      className="grnc_accordion_body"
                      style={{ display: "block" }}
                    >
                      <div className="grnc_according_category">
                        <div className="shape_row">
                          <div className="shape_row_inner">
                            <div className="shape_left">
                              {shapeData.data &&
                                parse(
                                  `${shapeData?.data?.shape_sub1_details1}`
                                )}
                            </div>
                            <div className="shape_right">
                              {shapeData.data &&
                                parse(
                                  `${shapeData?.data?.shape_sub1_details2}`
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="b">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="grnc_accordion_head">
                        <div className="grnc_accordion_head_inner">
                          {shapeData.data &&
                            parse(`${shapeData?.data?.shape_sub2_head}`)}
                          <img src={Arrow} />
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      className="grnc_accordion_body"
                      style={{ display: "block" }}
                    >
                      <div className="grnc_according_category">
                        <div className="shape_row">
                          <div className="shape_row_inner">
                            <div className="shape_left">
                              {shapeData.data &&
                                parse(
                                  `${shapeData?.data?.shape_sub2_details1}`
                                )}
                            </div>
                            <div className="shape_right">
                              {shapeData.data &&
                                parse(
                                  `${shapeData?.data?.shape_sub2_details2}`
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="c">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="grnc_accordion_head">
                        <div className="grnc_accordion_head_inner">
                          {shapeData.data &&
                            parse(`${shapeData?.data?.shape_sub3_head}`)}
                          <img src={Arrow} />
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      className="grnc_accordion_body"
                      style={{ display: "block" }}
                    >
                      <div className="grnc_according_category">
                        <div className="shape_row">
                          <div className="shape_row_inner">
                            <div className="shape_left">
                              {shapeData.data &&
                                parse(
                                  `${shapeData?.data?.shape_sub3_details1}`
                                )}
                            </div>
                            <div className="shape_right">
                              {shapeData.data &&
                                parse(
                                  `${shapeData?.data?.shape_sub3_details2}`
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>

            
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ShapeContent;

import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { langSelector } from "../../../redux/slices/langSlice";
import useApiClient from "../../../hooks/useApiClient";
import JoinImg from '../../../images/join_bg.webp'
import ComplienceIcon from '../../../images/compliance_icon.svg'
import Founder from '../../../images/founder_image.webp'
import TeamOne from '../../../images/team1.png'
import TeamTwo from '../../../images/team2.png'
import TeamThree from '../../../images/team3.png'
import TeamFour from '../../../images/team4.png'

const JoinContent = () => {
  const [joinData, setJoinData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=22");
    setJoinData(response);
  }, [selLang]);

  console.log("Join data", joinData)
  return (
    <div>

      <section id="content_section">
        <div className="join_us_page gerenric_padding position_relative">
          <div className="join_bg"><img src={JoinImg} alt="" /></div>
          <div className="page_width">
            <div className="gerenric_heading hdg_white">{joinData.data && parse(`${joinData?.data?.join_sec1_head}`)}</div>
            <div className="join_apply">
              <div className="apply_text">{joinData.data && parse(`${joinData?.data?.join_v2_apply_lbl}`)}</div>
              <div className="apply_link"><a href={`mailto:${joinData.data && parse(`${joinData?.data?.join_v2_apply_email}`)}`}>{joinData.data && parse(`${joinData?.data?.join_v2_apply_email}`)} <img src={ComplienceIcon} alt="" /></a></div>
            </div>
            <div className="gerenric_founder">
              <div className="founder_image"><img src={Founder} alt="" /></div>
            </div>
            <div className="gerenric_team column_full display_flex_auto">
              <div className="team_slider team_slider_img">
                <div>
                  <div className="team_col">
                    <div className="team_image"><img src={TeamOne} alt="" /></div>
                  </div>
                </div>
                <div>
                  <div className="team_col">
                    <div className="team_image"><img src={TeamTwo} alt="" /></div>
                  </div>
                </div>
                <div>
                  <div className="team_col">
                    <div className="team_image"><img src={TeamThree} alt="" /></div>
                  </div>
                </div>
                <div>
                  <div className="team_col">
                    <div className="team_image"><img src={TeamFour} alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="content_section">
        <div className="inner_heading h_mb_80">
          <h1> {joinData.data && parse(`${joinData?.data?.join_head}`)}</h1>
        </div>
        <div className="rejoindre_page">
          <div className="rejoindre_section">
            <div className="left_side">
              <h3>
                {joinData.data && parse(`${joinData?.data?.join_sec1_head}`)}
              </h3>
            </div>
            <div className="right_side">
              <div className="rejoindre_info">
                {joinData.data && parse(`${joinData?.data?.join_sec1_details}`)}
                <br />
                <br />
                <br />
                <div className="join-text">
                  <div className="small-text">
                    {joinData.data &&
                      parse(`${joinData?.data?.join_small_text}`)}
                  </div>
                </div>
              </div>
              <div
                className="rejoindre_circle_bg animateOnScroll"
                data-animation-type="zoomIn"
                data-timeout={400}
                data-offset-top={100}
                data-aos="zoom-in"
                data-aos-duration="1000"
              />
            </div>

            <div className="clearfix" />
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default JoinContent;

import React, { useState, useEffect } from "react";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import useApiClient from "../../hooks/useApiClient";
import JoinContent from "./components/joinContent";

const Joinus = () => {
 
  return (
    <div>
      <Header />
      <section id="content_section">
        <JoinContent />
      </section>
      <Footer />
    </div>
  );
};

export default Joinus;

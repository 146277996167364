import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import useApiClient from "../../hooks/useApiClient";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Image1 from "../../assests/images/conformite_image.svg";
import Image2 from "../../assests/images/conformite_image2.png";
import ContentImg from "../../images/gerenric_content_bg.svg";
import ComplianceIcon from "../../images/compliance_icon.svg";


const ComplianceContect = () => {
  const dispatch = useDispatch();
  const [complianceData, setComplianceData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=27");
    setComplianceData(response);
  }, [selLang]);

  console.log("compliance", complianceData);

  return (
    <div>


      <section id="content_section">
        <div className="compliance_page gerenric_padding position_relative">
          <div className="gerenric_content_bg"><img src={ContentImg} alt="" /></div>
          <div className="page_width">
            <div className="gerenric_content">
              <div className="section_1">
                <div className="content_block">
                  <div className="gerenric_heading"><span className="hdg_border_none">  {complianceData.data && complianceData?.data?.compliance_head}</span></div>
                  <p>  {complianceData.data &&
                    parse(`${complianceData?.data?.compliance_details}`)}</p>
                  {/* <p><a href="mailto:careers@aydofinance.com">careers@aydofinance.com <img src={ComplianceIcon} alt="" /></a></p> */}
                </div>
              </div>
              <div className="section_2">
                <div className="content_block">
                  <div className="customer_issuer">
                    <div className="ci_col">
                      <div className="ci_title"> {complianceData.data &&
                        complianceData?.data?.compliance_download_btn_head1}</div>
                      <div className="full_width"><a href="/files/Traitement des réclamations AYDO.pdf" target="_blank" ><div className="gerenric_button">{complianceData.data && complianceData?.data?.compliance_download_btn}</div></a></div>
                    </div>
                    <div className="ci_col">
                      <div className="ci_title"> {complianceData.data &&
                        complianceData?.data?.compliance_download_btn_head2}</div>
                      <div className="full_width"><a href={selLang=='en' ? "https://docs-vault2.s3.eu-west-1.amazonaws.com/website/Issuer+Selection+Policy+-+January+2023.pdf" : "https://docs-vault2.s3.eu-west-1.amazonaws.com/website/Politque+de+se%CC%81le%CC%81ction+e%CC%81metteurs+-+Janvier+2023.pdf"} target="_blank" ><div className="gerenric_button">{complianceData.data && complianceData?.data?.compliance_download_btn}</div></a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*CONTENT_SECTION_START*/}
      {/* <section id="content_section">
        <div className="inner_heading h_mb_80">
          <h1>
            {" "}
            {complianceData.data && complianceData?.data?.compliance_head}
          </h1>
        </div>
        <div className="conformite_page">
          <div
            className="conformiteside_left wow slideInLeft"
            data-wow-duration="1s"
          >
            <img src={Image1} alt="" />
          </div>
          <div className="conformite_download">
            <div className="conformite_left">
              <h2>
                {complianceData.data &&
                  complianceData?.data?.compliance_download_btn_head1}
              </h2>
              <div className="full_width">
                <Link
                  to="/files/Traitement des réclamations AYDO.pdf"
                  target="_blank"
                >
                  <div className="gerenric_button">
                    {complianceData.data &&
                      complianceData?.data?.compliance_download_btn}
                  </div>
                </Link>
              </div>
            </div>
            <div className="conformite_right">
              <h2>
                {complianceData.data &&
                  complianceData?.data?.compliance_download_btn_head2}
              </h2>
              <div className="full_width">
                <Link
                  to="/files/Politique de sélection des émetteurs AYDO.pdf"
                  target="_blank"
                >
                  <div className="gerenric_button">
                    {" "}
                    {complianceData.data && complianceData?.data?.compliance_download_btn}
                  </div>
                </Link>
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="conformite_info">
            {complianceData.data &&
              parse(`${complianceData?.data?.compliance_details}`)}

            <div className="small-text">
              {complianceData.data &&
                parse(`${complianceData?.data?.compliance_small_text}`)}
            </div>
          </div>
          <div
            className="conformiteside_right wow slideInRight"
            data-wow-duration="1s"
          >
            <img src={Image2} alt="" />
          </div>
        </div>
      </section> */}
      {/*CONTENT_SECTION_END*/}
    </div>
  );
};

export default ComplianceContect;

import React, { useState, useEffect } from "react";
import { langSelector } from "../../../redux/slices/langSlice";
import { useSelector } from "react-redux";
import useApiClient from "../../../hooks/useApiClient";
import ContactImg from '../../../images/form_contact_bg.webp'
import ContactForm from "./contactForm";
import parse from "html-react-parser";
import $ from "jquery";

const ContactContent = () => {
  const [contactData, setContactData] = useState(null);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=25");
    setContactData(response);
  }, [selLang]);

  var position = $(window).scrollTop();
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > position) {
      // console.log("scrollDown");
      $(".contact_cricle").addClass("cricleside_animation");
    } else {
      // console.log("scrollUp");
      $(".contact_cricle").removeClass("cricleside_animation");
    }
    position = scroll;
  });

  console.log("Contact Data", contactData)

  return (
    <div>

      <section id="content_section">
        <div className="contact_page ">
          <div className="gerenric_form_content gerenric_padding">
            <div className="fc_bg"><img src={ContactImg} alt="" /></div>
            <div className="page_width">
              <div className="gerenric_form_content_inner">
                <div className="fc_left">
                  <div className="gerenric_heading hdg_white">{contactData?.data &&
                    parse(`${contactData?.data?.contact_details}`)}</div>
                  <div className="fc_detail">
                    <ul>
                      <li>
                        <div className="fc_label">{contactData?.data &&
                          parse(`${contactData?.data?.contact_phone_lbl}`)}</div>
                        <div className="fc_value">{contactData?.data &&
                          parse(`${contactData?.data?.contact_phone_detail}`)}</div>
                      </li>
                      <li>
                        <div className="fc_label">{contactData?.data &&
                          parse(`${contactData?.data?.contact_email_lbl}`)}</div>
                        <div className="fc_value">{contactData?.data &&
                          parse(`${contactData?.data?.contact_email_detail}`)}</div>
                      </li>
                      <li>
                        <div className="fc_label">{contactData?.data &&
                          parse(`${contactData?.data?.contact_address_lbl}`)}</div>
                        <div className="fc_value">{contactData?.data &&
                          parse(`${contactData?.data?.contact_address_detail}`)}</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="fc_right">
                  <ContactForm contactFormData={contactData?.data} />
                </div>
              </div>
            </div>
          </div>
          <div className="main_contact_map">
            <div className="page_width">
              <div className="map_div"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.029136678954!2d2.3458864!3d48.8767211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e4003d48073%3A0x2ae5e6c43e1f1e93!2s26%20Rue%20de%20Montholon%2C%2075009%20Paris%2C%20France!5e0!3m2!1sen!2sua!4v1739282574497!5m2!1sen!2sua" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /></div>
            </div>
          </div>
        </div>
      </section>

      {/* {contactData != null && (
        <div>
          <div className="inner_heading h_mb_80">
            <h1 className="inner_heading_cricle purpleshadow">
              {contactData.data.contact_head}
            </h1>
          </div>
          <div className="contact_page">
            <div className="contact_left">
              <div className="contact_info">
                <div className="contact_cricle" />
                <ul>
                  <li>
                    <div className="contact_title">
                      {contactData.data.contact_address_lbl}
                    </div>
                    <div className="contact_detail">
                      {contactData.data.contact_address_detail}
                    </div>
                  </li>
                  <li>
                    <div className="contact_title">
                      {contactData.data.contact_email_lbl}
                    </div>
                    <div className="contact_detail">
                      {contactData.data &&
                        parse(`${contactData?.data?.contact_email_detail}`)}
                    </div>
                  </li>
                  <li>
                    <div className="contact_title">
                      {contactData.data.contact_phone_lbl}
                    </div>
                    <div className="contact_detail">
                      {contactData.data.contact_phone_detail}
                    </div>
                  </li>

                  <div className="small-text">
                    {contactData.data &&
                      parse(`${contactData?.data?.contact_small_text}`)}
                  </div>
                </ul>
              </div>
            </div>
            <div className="contact_right">
              <div className="map_section">
                <div className="map_iframe">
                  <iframe src="https://api.jawg.io/maps/fb85774c-ceba-4030-acff-b530db68bcc6/2335d513-71ae-4847-8b2a-525d97043ee3.html?access-token=NLGd5PFrcsLtFDHAIusjeH0eYTHFxpAdO0yWiF9GsRXCIZf1ke9so1Wl3Jz0be9X" />
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      )} */}

    </div>
  );
};

export default ContactContent;

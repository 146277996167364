import React, { useState, useEffect } from "react";
import Circle from "../../assests/images/solutions_cricle.svg";
import Recangle from "../../assests/images/solutions_rectangle.svg";
import BlackArrow from "../../assests/images/arrow_down_black.svg";
import WhiteCircle from "../../assests/images/white_cricle_logo.svg";
import BgCircle from "../../assests/images/bgcricle.png";
import MobileBgCircle from "../../assests/images/bgcricle_mobile.png";
import Enterprise from "../../assests/images/entreprises_image_1.png";
import Enterprise2 from "../../assests/images/Axel.webp";
import Solutions from "../../images/1.webp";
import SolutionsOne from "../../images/2.webp";
import SolutionsTwo from "../../images/3.webp";
import BlurImg from "../../images/blur_bg.webp";
import SolutionsImg from "../../images/sp_bg.svg";
import IconsOne from "../../images/sp_icon1.svg";
import Arrow from "../../images/arrow_down.svg";
import SpPrice from "../../images/sp_price_icon.svg";
import SpPriceOne from "../../images/sp_price_icon_1.svg";
import SpPriceTwo from "../../images/sp_price_icon_2.svg";


import { useSelector } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";

import parse from "html-react-parser";
import useApiClient from "../../hooks/useApiClient";

const SolutionContent = () => {
  const [solutionData, setSolutionData] = useState([]);
  const selLang = useSelector(langSelector);
  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=23");
    setSolutionData(response);
  }, [selLang]);

  return (
    <div>

      <section id="content_section">
        <div className="solution_page">
          <div className="solution_step gerenric_padding">
            <div className="page_width">
              {/* Main Heading */}
              <div className="gerenric_heading"> {solutionData.data &&
                parse(`${solutionData?.data?.solution_v2_sec1_head}`)}</div>
              <div className="solution_step_inner">
                <div className="solution_step_detail">
                  <div className="step_block solution_step_inner">
                    <div className="solution_step_detail">
                      {solutionData.data &&
                        parse(`${solutionData?.data?.solution_v2_sec1_step1}`)}
                    </div>
                    <div className="solution_step_image"><img src={Solutions} alt="" /></div>
                  </div>
                  <div className="solution_step_detail">
                    <div className="step_block solution_step_inner">
                      <div className="solution_step_detail">
                        {solutionData.data &&
                          parse(`${solutionData?.data?.solution_v2_sec1_step2}`)}
                      </div>
                      <div className="solution_step_image"><img src={SolutionsOne} alt="" /></div>
                    </div>
                  </div>
                  <div className="solution_step_detail">
                    <div className="step_block solution_step_inner">
                      <div className="solution_step_detail">
                        {solutionData.data &&
                          parse(`${solutionData?.data?.solution_v2_sec1_step3}`)}
                      </div>
                      <div className="solution_step_image"><img src={SolutionsTwo} alt="" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="solution_product">
            <div className="blur_content_bg"><img src={BlurImg} alt="" /></div>
            <div className="solution_product_inner gerenric_padding">
              <div className="sp_bg"><img src={SolutionsImg} alt="" /></div>
              <div className="page_width">
                <div className="gerenric_heading hdg_white"> {solutionData.data &&
                  parse(`${solutionData?.data?.solution_v2_sec2_head}`)}</div>
                <div className="solution_product_row">
                  <div className="sp_col">
                    <div className="sp_item sp_blue_bg">
                      <div className="sp_item_inner">
                        <div className="sp_icon"><img src={IconsOne} alt="" /></div>
                        <div className="sp_title"> {solutionData.data &&
                          parse(`${solutionData?.data?.solution_v2_sec2_title1}`)}
                        </div>
                        {solutionData.data &&
                          parse(`${solutionData?.data?.solution_v2_sec2_detail1}`)}
                      </div>
                    </div>
                    <div className="gerenric_arrow_down_icon"><img src={Arrow} alt="" /></div>
                  </div>
                  <div className="sp_col">
                    <div className="sp_item">
                      <div className="sp_item_inner">
                        <div className="sp_icon"><img src={SpPriceOne} alt="" /></div>
                        <div className="sp_title"> {solutionData.data &&
                          parse(`${solutionData?.data?.solution_v2_sec2_title2}`)}
                        </div>
                        {solutionData.data &&
                          parse(`${solutionData?.data?.solution_v2_sec2_detail2}`)}
                      </div>
                    </div>
                    <div className="gerenric_arrow_down_icon"><img src={Arrow} alt="" /></div>
                  </div>
                  <div className="sp_col">
                    <div className="sp_item sp_yellow_bg">
                      <div className="sp_item_inner">
                        <div className="sp_icon"><img src={SpPriceTwo} alt="" /></div>
                        <div className="sp_title"> {solutionData.data &&
                          parse(`${solutionData?.data?.solution_v2_sec2_title3}`)}
                        </div>
                        {solutionData.data &&
                          parse(`${solutionData?.data?.solution_v2_sec2_detail3}`)}
                      </div>
                    </div>
                    <div className="gerenric_arrow_down_icon"><img src={Arrow} alt="" /></div>
                  </div>
                </div>
                <div className="solution_product_price">
                  <div className="sp_price_row">
                    <div className="sp_price_box">
                      <div className="sp_price_icon"><img src={SpPrice} alt="" /></div>

                      <div className="sp_price_text"> {solutionData.data &&
                        parse(`${solutionData?.data?.solution_v2_sec3_detail1}`)}</div>
                    </div>
                    <div className="gerenric_arrow_down_icon"><img src={Arrow} alt="" /></div>
                  </div>
                  <div className="sp_price_row">
                    <div className="sp_price_box">
                      <div className="sp_price_icon"><img src={SpPrice} alt="" /></div>
                      <div className="sp_price_text"> {solutionData.data &&
                        parse(`${solutionData?.data?.solution_v2_sec3_detail2}`)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/*CONTENT_SECTION_START*/}
      {/* <section id="content_section">
        <div className="inner_heading">
          {solutionData.data && <h1>{solutionData?.data?.solution_head}</h1>}
        </div>
        <div className="solutions_page">
          <div className="solutions_section1">
            <div className="solutions_paragraph">
              <div className="solutions_cricle_image">
                <img src={Circle} alt="" />
              </div>
              <div className="solutions_rectangle_image">
                <img src={Recangle} alt="" />
              </div>
              {solutionData.data &&
                parse(`${solutionData?.data?.solution_details1}`)}
            </div>
            <div className="solution_arrow">
              <a href="#section_1">
                <img src={BlackArrow} alt="" />
              </a>
            </div>
            <div className="produits_section " data-aos="fade-up">
              <div id="section_1"></div>
              <div className="produits_inner">
                <div className="produits_circle">
                  <div className="produits_list">
                    {solutionData.data && (
                      <h2>{solutionData?.data?.solution_sub1_head}</h2>
                    )}
                    <ul>
                      <li>
                        <div className="producit_button">
                          {solutionData.data &&
                            solutionData?.data?.solution_sub1_btn1}
                        </div>
                      </li>
                      <li>
                        <div className="producit_button">
                          {" "}
                          {solutionData.data &&
                            solutionData?.data?.solution_sub1_btn2}
                        </div>
                      </li>
                      <li>
                        <div className="producit_button">
                          {" "}
                          {solutionData.data &&
                            solutionData?.data?.solution_sub1_btn3}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="formats_circle">
                  <div className="plateforme_circle">
                    <div className="plateforme_circle_absolut">
                      <div>
                        <div
                          className="criclelogo "
                          data-aos="zoom-in"
                          data-aos-delay="200"
                          data-aos-offset="100"
                        >
                          <img src={WhiteCircle} alt="" />
                        </div>
                        <p>
                          {solutionData.data &&
                            solutionData?.data?.solution_sub2_details}
                        </p>
                      </div>
                    </div>
                    <img src={BgCircle} alt="" className="desktop_cricle" />
                    <img
                      src={MobileBgCircle}
                      alt=""
                      className="mobile_cricle"
                    />
                  </div>
                  <div className="produits_list">
                    <h2>
                      {solutionData.data &&
                        solutionData?.data?.solution_sub2_head}
                    </h2>
                    <ul>
                      <li>
                        <div className="producit_button">
                          {solutionData.data &&
                            solutionData?.data?.solution_sub2_btn1}
                        </div>
                      </li>
                      <li>
                        <div className="producit_button">
                          {solutionData.data &&
                            solutionData?.data?.solution_sub2_btn2}
                        </div>
                      </li>
                      <li>
                        <div className="producit_button">
                          {solutionData.data &&
                            solutionData?.data?.solution_sub2_btn3}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
            <div className="solutions_paragraph">
              {solutionData.data &&
                parse(`${solutionData?.data?.solution_details2}`)}
            </div>
            <div className="solution_arrow">
              <a href="#section_2">
                <img src={BlackArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="solutions_section2">
            <div id="section_2"></div>
            <div className="solution_circle">
              <img src={Enterprise} alt="" />
            </div>
            <div className="left_side">
              <h3>
                {solutionData.data &&
                  parse(`${solutionData?.data?.solution_sec1_head}`)}
              </h3>
              <div className="entreprises_image">
                <img src={Enterprise2} alt="" />
              </div>
              <div className="entreprises_title">Axel Legros</div>
            </div>
            <div className="right_side">
              {solutionData.data &&
                parse(`${solutionData?.data?.solution_sec1_details}`)}
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </section> */}
      {/*CONTENT_SECTION_END*/}
    </div>
  );
};

export default SolutionContent;

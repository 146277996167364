import React, {useEffect} from 'react';
import detectBrowserLanguage from 'detect-browser-language';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage, langSelector } from '../redux/slices/langSlice';
import { client } from '../api/client';

const useApiClient = () => {
    const persistedLang = useSelector(langSelector);
  

    const makeApiCall=(endpoint, customConfig = {method: 'GET'})=>{
        if(customConfig.method=='GET'){
            return client.get(endpoint+'&lang='+persistedLang);
        }
        else{
            return client.post(endpoint, {...customConfig, lang: persistedLang});
        }
    }

    return [makeApiCall];
};

export default useApiClient;
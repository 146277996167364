import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { langSelector } from "../../../redux/slices/langSlice";
import { addLogin, getLoginDataSelector } from '../../../redux/slices/contactSlice'
import useApiClient from "../../../hooks/useApiClient";
import parse from "html-react-parser";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import RequestImg from '../../../images/join_bg.webp'
import FormImg from '../../../images/form_contact_bg.webp'
import LoadingOverlay from "react-loading-overlay";
import { Audio } from "react-loader-spinner";
import axios from 'axios'
const Login = () => {
    const dispatch = useDispatch()
    const selLang = useSelector(langSelector);
    const [loginData, setLoginData] = useState(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null);
    // const [success, setSucces] = useState(false)
    const status = useSelector((state) => state.contactform.status);
    const LoginResponse = useSelector(getLoginDataSelector);
    const [makeApiCall] = useApiClient();

    useEffect(async () => {
        let response = await makeApiCall("get_site_contents&page_id=1");
        setLoginData(response);
    }, [selLang]);
    useEffect(() => {
        if (status === 'loged_succesfuly' && LoginResponse != null && LoginResponse?.status == 1) {
            // axios.post(`${LoginResponse?.redirect_url}&user_name=${email}&user_password=${password}`)
            window.open(`${LoginResponse?.redirect_url}`);
        }
    }, [status]);

    const SaveLogin = () => {
        if (password && email) {
            dispatch(addLogin({ from_shape: '1', user_name: email, user_password: password }));
            setError(null);
        } else {
            setError("Please Fill in all fields First");
        }
    };

    console.log("Login Data", loginData)

    return (
        <div>
            <Header />
            <section id="content_section">
                <div className="login_page">
                    <div className="gerenric_form_content gerenric_padding">
                        <div className="fc_top_bg"><img src={RequestImg} alt="" /></div>
                        <div className="fc_bg"><img src={FormImg} alt="" /></div>
                        <div className="page_width">
                            <div className="gerenric_form_content_inner align_center">
                                <div className="fc_left">
                                    <div className="gerenric_heading hdg_white">
                                        {loginData?.data &&
                                            parse(`${loginData?.data?.shape_login_v2_details}`)}
                                    </div>
                                </div>
                                <div className="fc_right">
                                    <LoadingOverlay
                                        tag="div"
                                        active={status == "loging"}
                                        styles={{ overlay: (base) => ({ ...base, background: "#fff", opacity: 0.5 }) }}
                                        spinner={<Audio height="100" width="100" color="#4fa94d" ariaLabel="audio-loading" wrapperStyle={{}} wrapperClass="wrapper-class" visible={true} />}
                                    >
                                        <div className="form_box">
                                            <h2>{loginData?.data &&
                                                parse(`${loginData?.data?.login}`)}</h2>
                                            <p>{loginData?.data &&
                                                parse(`${loginData?.data?.shape_login_v2_des}`)} <Link to={selLang == 'fr' ? '/fr/request_sent' : '/request_sent'} className="request_text">{loginData?.data?.shape_login_v2_form_request_btn}</Link></p>
                                            <ul>
                                                <li><input type="text" className="gerenric_input myInputCentered" placeholder={loginData?.data?.shape_login_v2_form_email} value={email} onChange={(e) => setEmail(e.target.value)} /></li>
                                                <li><input type="password" className="gerenric_input myInputCentered" placeholder={loginData?.data?.shape_login_v2_form_password} value={password} onChange={(e) => setPassword(e.target.value)} /><div className="input_icon"><img src="images/password_icon.png" alt="" /></div></li>
                                                {error && <div className="error_msg_one">{error}</div>}
                                                {LoginResponse && LoginResponse?.status == 0 ? <div className="error_msg_one">{LoginResponse?.message}</div> : ''}
                                                <li><input type="button" className="gerenric_button" defaultValue={loginData?.data?.login} onClick={SaveLogin} /></li>
                                                <li><div className="forgot_password"><a >{loginData?.data?.shape_login_v2_form_forgot}</a></div></li>
                                                <li><div className="form_term">{loginData?.data?.shape_login_v2_form_detail} <Link to={selLang == 'fr' ? '/fr/legal' : '/legal'} >{loginData?.data?.shape_login_v2_form_term_btn}</Link> {selLang == 'fr' ? 'et' : 'and'}  <Link to={selLang == 'fr' ? '/fr/privacypolicy' : '/privacypolicy'}>{loginData?.data?.shape_login_v2_form_privacy_btn}</Link></div></li>
                                            </ul>
                                        </div>

                                    </LoadingOverlay>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Login
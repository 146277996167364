import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { langSelector } from "../../../redux/slices/langSlice";
import useApiClient from "../../../hooks/useApiClient";
import LegalContentImg from '../../../images/gerenric_content_bg.svg'
import parse from "html-react-parser";
const LegalContent = () => {
  const [legalData, setLegalData] = useState([]);
  const selLang = useSelector(langSelector);
  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=26");
    setLegalData(response);
  }, [selLang]);


  return (
    <div>

      <section id="content_section">
        <div className="legal_page gerenric_padding position_relative">
          <div className="gerenric_content_bg"><img src={LegalContentImg} alt="" /></div>
          <div className="page_width">
            <div className="gerenric_content">
              <div className="content_block">
                <div className="gerenric_heading"><span className="hdg_border_none">{legalData.data && legalData?.data?.ledgal_head}</span></div>
                {legalData.data && parse(`${legalData?.data?.ledgal_details}`)}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="inner_heading h_mb_80">
          <br />
          <h1>{legalData.data && legalData?.data?.ledgal_head}</h1>
          <br />
          <br />
        </div>
        <div className="legal_content">
          <div className="all_content">
            {legalData.data && parse(`${legalData?.data?.ledgal_details}`)}
          </div>
        </div> */}
    </div>
  );
};

export default LegalContent;

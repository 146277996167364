import React, { useState, useEffect } from "react";
import Historic from "../../../assests/images/histoire_image1.png";
import Historic2 from "../../../assests/images/history_image2.png";
import Historic3 from "../../../assests/images/history_image3.jpg";
import Genric from '../../../images/gerenric_content_bg.svg'
import Fky from '../../../images/feky_icon.png'
import PowerIco from '../../../images/power_data_icon.svg'
import Experties from '../../../images/expertise_icon.svg'
import BlurImg from '../../../images/blur_bg.webp'
import TeamOne from '../../../images/about_team_bg1.svg'
import TeamTwo from '../../../images/about_team_bg2.webp'
import Founder from '../../../images/founder_image.webp'
import TeamEx from '../../../images/team1.png'
import TeamExone from '../../../images/team2.png'
import TeamExTwo from '../../../images/team3.png'
import TeamExThree from '../../../images/team4.png'


import { useSelector } from "react-redux";
import { langSelector } from "../../../redux/slices/langSlice";
import useApiClient from "../../../hooks/useApiClient";
import BannerArrow1 from "../../../assests/images/arrow_down2_banner_white.svg";
import BannerArrow from "../../../assests/images/arrow_down2_banner.svg";
import parse from "html-react-parser";
const Contents = () => {
  const [aboutData, setAboutData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=20");
    setAboutData(response);
  }, [selLang]);

  return (
    <div>
      {/*CONTENT_SECTION_START*/}

      <section id="content_section">
        <div className="about_page position_relative">
          <div className="gerenric_content_bg"><img src={Genric} alt="" /></div>
          <div className="about_different_section gerenric_padding">
            <div className="page_width">
              {/* heading */}
              <div className="gerenric_heading">{aboutData.data &&
                parse(`${aboutData?.data?.about_v2_sec1_head}`)}</div>
              {/* description  */}
              <div className="about_different_inner">
                <div className="about_col">
                  <div className="about_item">
                    <div className="about_icon"><img src={Fky} alt="" /></div>
                    {/* heading  */}
                    <div className="about_title">{aboutData.data &&
                      parse(`${aboutData?.data?.about_v2_sec1_subhead1}`)}</div>
                    {/* description */}
                    {aboutData.data &&
                      parse(`${aboutData?.data?.about_v2_sec1_subdetails1}`)}
                  </div>
                </div>
                <div className="about_col">
                  <div className="about_item">
                    <div className="about_icon"><img src={PowerIco} alt="" /></div>
                    <div className="about_title">{aboutData.data &&
                      parse(`${aboutData?.data?.about_v2_sec1_subhead2}`)}</div>
                    {/* description */}
                    {aboutData.data &&
                      parse(`${aboutData?.data?.about_v2_sec1_subdetails2}`)}
                  </div>
                </div>
                <div className="about_col">
                  <div className="about_item">
                    <div className="about_icon"><img src={Experties} alt="" /></div>
                    <div className="about_title">{aboutData.data &&
                      parse(`${aboutData?.data?.about_v2_sec1_subhead3}`)}</div>
                    {/* description */}
                    {aboutData.data &&
                      parse(`${aboutData?.data?.about_v2_sec1_subdetails3}`)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about_team_section">
            <div className="blur_content_bg"><img src={BlurImg} alt="" /></div>
            <div className="about_team_inner gerenric_padding">
              <div className="about_team_bg_1"><img src={TeamOne} alt="" /></div>
              <div className="about_team_bg_2"><img src={TeamTwo} alt="" /></div>
              <div className="page_width">
                <div className="gerenric_heading hdg_white">
                  {aboutData.data &&
                    parse(`${aboutData?.data?.about_v2_sec2_head}`)}
                </div>
                <div className="gerenric_founder">
                  <div className="founder_image"><img src={Founder} alt="" /></div>
                  <div className="founder_block">
                    <h2> { parse(`${aboutData?.data?.about_v2_lbl_founder}`)} </h2>
                    <div className="founder_row">
                      <div className="founder_name">Pierre-Yves Breton</div>
                      <div className="founder_name">Jordan Sfez</div>
                      <div className="founder_name">Arthur Teixeira</div>
                    </div>
                    {aboutData.data &&
                      parse(`${aboutData?.data?.about_v2_sec2_details}`)}
                  </div>
                </div>
                <div className="gerenric_team column_full display_flex_auto">
                  <div className="team_slider team_slider_img">
                    <div>
                      <div className="team_col">
                        <div className="team_image"><img src={TeamEx} alt="" /></div>
                        <div className="team_detail">
                          {aboutData.data &&
                            parse(`${aboutData?.data?.about_v2_sec2_team_mem1}`)}

                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="team_col">
                        <div className="team_image"><img src={TeamExone} alt="" /></div>
                        <div className="team_detail">
                          {aboutData.data &&
                            parse(`${aboutData?.data?.about_v2_sec2_team_mem2}`)}

                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="team_col">
                        <div className="team_image"><img src={TeamExTwo} alt="" /></div>
                        <div className="team_detail">
                          {aboutData.data &&
                            parse(`${aboutData?.data?.about_v2_sec2_team_mem3}`)}

                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="team_col">
                        <div className="team_image"><img src={TeamExThree} alt="" /></div>
                        <div className="team_detail">
                          {aboutData.data &&
                            parse(`${aboutData?.data?.about_v2_sec2_team_mem4}`)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section id="content_section">
        <div className="inner_heading h_mb_80">
          {aboutData.data && <h1>{aboutData?.data?.about_head}</h1>}

        </div>
        <div className="propos_page">
          <div className="mission_section">
            <div className="left_side">
              {aboutData.data && <h3>{aboutData?.data?.about_sec1_head}</h3>}
            </div>
            <div className="right_side">
              {aboutData.data &&
                parse(`${aboutData?.data?.about_sec1_details}`)}
            </div>
            <div className="clearfix" />
            <br />
            <br />
            <br />
            <a href="#section_1" className="banner_arrow">
              <img src={BannerArrow1} alt="" />
            </a>
            <br />
            <br />
          </div>
          <div id="section_1"></div>
          <div className="eux_section">
            {aboutData.data && <h2>{aboutData?.data?.about_sec2_head}</h2>}
          </div>
          <a href="#section_2" className="banner_arrow">
            <img src={BannerArrow} alt="" />
          </a>
          <br />
          <br />
          <br />

          <div className="histoire_section">
            <div id="section_2"></div>
            <div className="histoire_stn1">
              <div className="right_side">
                {aboutData.data && <h3>{aboutData?.data?.about_sec3_head}</h3>}

                <div className="histoireimage1">
                  <img src={Historic} alt="" />
                </div>
              </div>
              <div className="left_side">
                {aboutData.data &&
                  parse(`${aboutData?.data?.about_sec3_details_1}`)}
              </div>
              <div className="clearfix" />
            </div>
            <div className="histoire_stn2">
              <div className="left_side">
                <div className="histoireimage2">
                  <div className="histoireimg_1">
                    <img src={Historic2} alt="" />
                  </div>
                  <div className="histoireimg_2">
                    <img src={Historic3} alt="" />
                    <div className="histoire_links">
                      <ul>
                        <li>
                          <a href="javascript:void(0)">Pierre-Yves Breton</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Jordan Sfez</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Arthur Teixeira </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right_side">
                {aboutData.data &&
                  parse(`${aboutData?.data?.about_sec3_details_2}`)}
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </section> */}
      {/*CONTENT_SECTION_END*/}
    </div>
  );
};

export default Contents;

import React from "react";
import MainHeader from "../../layout/Mainheader";
import Footer from "../../layout/Footer";
import Contents from "./components/content";
import Header from "../../layout/Header";
const Propos = () => {
  return (
    <div>
      <Header />
      <Contents />
      <Footer />
    </div>
  );
};

export default Propos;

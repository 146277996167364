
export async function client(endpoint) {
    let headers = { 'Content-Type': 'application/json;charset=UTF-8', "Authorization": "Basic " + btoa('newco:admin123')}
    let url = process.env.REACT_APP_API_URL+endpoint;

    const config = {
      method: 'GET',
      headers: {
        ...headers,
      },
    }

    let data;
    try {
      const response = await fetch(url, config);
      data = await response.json();

      if (data.status==1 || data.status=='1' || data.token!=null || data) {
        return data;
      }
      throw new Error(response.message);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data);
    }
}

client.get = function (endpoint) {
    return client(endpoint)
}

  
import React from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import ComplianceContect from "./complianceContect";
const Compliance = () => {
  return (
    <div>
      <Header />
      <ComplianceContect />
      <Footer />
    </div>
  );
};

export default Compliance;

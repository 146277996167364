import React from "react";
import Footer from "../../layout/Footer";
import ContenctMangement from "./component/contenctMangement";
import Header from "../../layout/Header";

const NsManagement = () => {
  return (
    <div>
      <Header />
      <ContenctMangement />
      <Footer />
    </div>
  );
};

export default NsManagement;

import React from "react";
import Mainheader from "../../layout/Mainheader";
import Footer from "../../layout/Footer";
import SolutionContent from "./solution-content";
import Header from "../../layout/Header";
const NsSolution = () => {
  return (
    <div>
      <Header />
      <SolutionContent />
      <Footer />
    </div>
  );
};

export default NsSolution;

import React from "react";
import Mainheader from "../../layout/Mainheader";
import Footer from "../../layout/Footer";
import Banner from "./component/banner";
import Content from "./component/content";
const Homepage = () => {
  return (
    <div>
      <Mainheader />
      <Banner />
      <Content />
      <Footer />
    </div>
  );
};

export default Homepage;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import EnFlag from '../images/en_icon_flag.svg'
import FrFlag from '../images/fr_icon_flag.svg'
import { changeLanguage, langSelector } from "../redux/slices/langSlice";
import { useHistory } from "react-router-dom";

const ChangeLang = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selLang = useSelector(langSelector);


  const langSwitched = (code) => {
    const location = window.location.pathname;
    if (selLang == 'en' && code == 'fr') {
      history.replace('/fr' + location);
    }
    else if (selLang == 'fr' && code == 'en') {
      let newLocation = location.split('/fr')[1];
      history.replace(newLocation);
    }
    dispatch(changeLanguage(code));
  }

  return (
    <>
      <div className="header_language">
        <ul>
          <li><a onClick={() => langSwitched('en')}><div className={`language_box ${selLang == "fr" ? "en_box" : ""}`} ><img src={EnFlag} alt="" /> EN</div></a></li>
          <li><a onClick={() => langSwitched('fr')}><div className={`language_box ${selLang == "en" ? "en_box" : ""}`}><img src={FrFlag} alt="" /> FR</div></a></li>
        </ul>
      </div>
      {/* <div className="language_div">
      <a
        className={selLang == "en" ? "language_active" : ""}
        onClick={() => langSwitched('en')}
      >
        EN
      </a>
      <a
        className={selLang == "fr" ? "language_active" : ""}
        onClick={() => langSwitched('fr')}
      >
        FR
      </a>
    </div> */}
    </>
  );
};

export default ChangeLang;

import React, { useState, useEffect } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import FooterLogo from "../images/footer_logo.svg";
import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";
import { langSelector } from "../redux/slices/langSlice";
import $ from "jquery";
import useApiClient from "../hooks/useApiClient";
import parse from "html-react-parser";

const Footer = () => {
  const [footerData, setFooterData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=18");
    setFooterData(response);
  }, [selLang]);

  // useEffect(() => {
  $(window).scroll(function () {
    var height = $(window).scrollTop();
    if (height >= 400) {
      $(".hideEn").fadeIn();
    } else {
      $(".hideEn").fadeOut();
    }
  });
  // }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* <ScrollToTop /> */}
      {/* {visible === 1 && ( */}
      <div className="hiden1">
        <div className="hideEn" onClick={scrollToTop}>
          <FaArrowCircleUp />
        </div>
      </div>

      {/* )} */}

      <div>

        {/*FOOTER_SECTION_START*/}
        <footer id="footer_section">
          <div className="scrollarrowtop"><i className="fa fa-arrow-up" aria-hidden="true" /></div>
          <div className="page_width">
            <div className="footer_top">
              <div className="footer_logo"> <NavLink
                to={`${selLang == "fr" ? "/fr/" : "/"}`}
                onClick={scrollToTop}
              >
                <img src={FooterLogo} alt="footerlogo" />
              </NavLink></div>
              <div className="footer_top_inner">
                <div className="footer_col">
                  {footerData?.data && parse(footerData?.data?.footer_detail)}
                  {/* <p>Ready to invest? <br />Need more information?</p> */}
                  <div className="full_width">
                    <NavLink
                      to={`${selLang == "fr" ? "/fr/contact" : "/contact"}`}
                      onClick={scrollToTop}
                    >
                      <div className="gerenric_button"> {footerData?.data?.get_in_touch}</div>
                    </NavLink>
                    {/* <a href="contact.html"></a> */}
                  </div>
                </div>
                <div className="footer_col">
                  <ul>
                    <li>{footerData.data && (
                      <NavLink
                        to={`${selLang == "fr" ? "/fr/about" : "/about"}`}
                        onClick={scrollToTop}
                      >
                        {footerData?.data?.menu_about_aydo}
                      </NavLink>
                    )}</li>
                    <li> {footerData.data && (
                      <NavLink
                        to={`${selLang == "fr" ? "/fr/about-shape" : "/about-shape"}`}
                        onClick={scrollToTop}
                      >
                        {footerData?.data?.menu_shape}
                      </NavLink>
                    )}</li>
                  </ul>
                </div>
                <div className="footer_col">
                  <ul>
                    <li> {footerData.data && (
                      <NavLink
                        to={`${selLang == "fr" ? "/fr/solutions" : "/solutions"
                          }`}
                        onClick={scrollToTop}
                      >
                        {footerData?.data?.menu_our_solutions}
                      </NavLink>
                    )}</li>
                    <li> {footerData.data && (
                      <NavLink
                        to={`${selLang == "fr" ? "/fr/commitments" : "/commitments"
                          }`}
                        onClick={scrollToTop}
                      >
                        {footerData?.data?.menu_our_commitments}
                      </NavLink>
                    )}</li>
                  </ul>
                </div>
                <div className="footer_col">
                  <ul>
                    <li><span>{footerData.data && (
                      <NavLink
                        to={`${selLang == "fr" ? "/fr/career" : "/career"}`}
                        onClick={scrollToTop}
                      >
                        {footerData?.data?.menu_join_us}
                      </NavLink>
                    )}</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer_bottom">
              <ul>
                <li> {footerData.data && (
                  <NavLink
                    to={`${selLang == "fr" ? "/fr/legal" : "/legal"}`}
                    onClick={scrollToTop}
                  >
                    {footerData?.data?.menu_legal}
                  </NavLink>
                )}</li>
                <li> {footerData.data && (
                  <NavLink
                    to={`${selLang == "fr" ? "/fr/compliance" : "/compliance"
                      }`}
                    onClick={scrollToTop}
                  >
                    {footerData?.data?.menu_compliance}
                  </NavLink>
                )}</li>
                <li>
                  <NavLink
                    to={`${selLang == "fr" ? "/fr/privacypolicy" : "/privacypolicy"
                      }`}
                    onClick={scrollToTop}
                  >
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${selLang == "fr" ? "/fr/cookiepolicy" : "/cookiepolicy"
                      }`}
                    onClick={scrollToTop}
                  >
                    Cookies
                  </NavLink>
                </li>
                <li><span>© AYDO — Creative Finance</span></li>
              </ul>
            </div>
          </div>
        </footer>
        {/*FOOTER_SECTION_END*/}


        {/*FOOTER_SECTION_END*/}
        {/* <footer id="footer_section">
          <div className="page_container">
            <div className="footer_top">
              <div className="footerlogo">
                <NavLink
                  to={`${selLang == "fr" ? "/fr/" : "/"}`}
                  onClick={scrollToTop}
                >
                  <img src={FooterLogo} alt="footerlogo" />
                </NavLink>
              </div>
              <div className="footerlink">
                <div className="footercol">
                  <ul>
                    <li>
                      {footerData.data && (
                        <NavLink
                          to={`${selLang == "fr" ? "/fr/about" : "/about"}`}
                          onClick={scrollToTop}
                        >
                          {footerData?.data?.menu_about_aydo}
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {footerData.data && (
                        <NavLink
                          to={`${selLang == "fr" ? "/fr/about-shape" : "/about-shape"}`}
                          onClick={scrollToTop}
                        >
                          {footerData?.data?.menu_shape}
                        </NavLink>
                      )}
                    </li> 
                    <li>
                      {footerData.data && (
                        <NavLink
                          to={`${selLang == "fr" ? "/fr/career" : "/career"}`}
                          onClick={scrollToTop}
                        >
                          {footerData?.data?.menu_join_us}
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </div>
                <div className="footercol">
                  <ul>
                    <li>
                      {footerData.data && (
                        <NavLink
                          to={`${selLang == "fr" ? "/fr/solutions" : "/solutions"
                            }`}
                          onClick={scrollToTop}
                        >
                          {footerData?.data?.menu_our_solutions}
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {footerData.data && (
                        <NavLink
                          to={`${selLang == "fr" ? "/fr/commitments" : "/commitments"
                            }`}
                          onClick={scrollToTop}
                        >
                          {footerData?.data?.menu_our_commitments}
                        </NavLink>
                      )}
                    </li>
                    <li>
                      <NavLink
                        to={`${selLang == "fr" ? "/fr/contact" : "/contact"}`}
                        onClick={scrollToTop}
                      >
                        CONTACT
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="certificateslink">
                <ul>
                  <li>© AYDO — Creative Finance</li>
                  <li>
                    {footerData.data && (
                      <NavLink
                        to={`${selLang == "fr" ? "/fr/legal" : "/legal"}`}
                        onClick={scrollToTop}
                      >
                        {footerData?.data?.menu_legal}
                      </NavLink>
                    )}
                  </li>
                  <li>
                    {footerData.data && (
                      <NavLink
                        to={`${selLang == "fr" ? "/fr/compliance" : "/compliance"
                          }`}
                        onClick={scrollToTop}
                      >
                        {footerData?.data?.menu_compliance}
                      </NavLink>
                    )}
                  </li>
                  <li>
                    {footerData.data && (
                      <NavLink to="/" onClick={scrollToTop}>
                        {footerData?.data?.menu_disclaimer}
                      </NavLink>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer> */}
        {/*FOOTER_SECTION_END*/}
      </div>
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { langSelector } from "../../redux/slices/langSlice";
import { requestSent } from '../../redux/slices/contactSlice'
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Audio } from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";

const RequestForm = ({ requestData }) => {
    const dispatch = useDispatch()
    const selLang = useSelector(langSelector);
    const [recaptchaValue, setRecaptchaValue] = useState("");
    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [comapny, setCompany] = useState('')
    const [number, setNumber] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState(null);
    const [success, setSucces] = useState(false)
    const status = useSelector((state) => state.contactform.status);
    useEffect(() => {
        if (status === 'sent_email_succesfuly') {
            setFName('');
            setLName('');
            setEmail('');
            setMessage('');
            setCompany('');
            setNumber('');
            setSucces(true)
        }
    }, [status]);


    const SaveContactForm = () => {
        if (!recaptchaValue) {
            setError("Please complete the reCAPTCHA.");
        } else {
            if (fname && lname && email) {
                dispatch(requestSent({ fname: fname, lname: lname, email: email, company: comapny, phone: number, message: message }));
                setError(null);
            } else {
                setError("Please Fill in all fields First");
            }
        }

    };

    return (
        <LoadingOverlay
            tag="div"
            active={status == "sending_email"}
            styles={{ overlay: (base) => ({ ...base, background: "#fff", opacity: 0.5 }) }}
            spinner={<Audio height="100" width="100" color="#4fa94d" ariaLabel="audio-loading" wrapperStyle={{}} wrapperClass="wrapper-class" visible={true} />}
        >

            <div className="form_box">
                <h2>{requestData?.req_access_v2_form_title}</h2>
                <p>{requestData?.req_access_v2_form_dec}  <Link to={selLang == 'fr' ? '/fr/login' : '/login'} className="request_text">{requestData?.req_access_v2_form_login_btn}</Link></p>
                {success && <div className="error_msg">Request Email Sent Succesfully</div>}
                {error && <div className="error_msg">{error}</div>}
                <ul>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={requestData?.req_access_v2_form_fname} value={fname} onChange={(e) => setFName(e.target.value)} /></li>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={requestData?.req_access_v2_form_lname} value={lname} onChange={(e) => setLName(e.target.value)} /></li>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={requestData?.req_access_v2_form_email} value={email} onChange={(e) => setEmail(e.target.value)} /></li>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={requestData?.req_access_v2_form_company} value={comapny} onChange={(e) => setCompany(e.target.value)} /></li>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={requestData?.req_access_v2_form_phone} value={number} onChange={(e) => setNumber(e.target.value)} /></li>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={requestData?.req_access_v2_form_message} value={message} onChange={(e) => setMessage(e.target.value)} /></li>
                    <li>
                        <ReCAPTCHA
                            sitekey="6LezCRwoAAAAAA5eg6U_-B664HUNdXFc3Cf4cj8M"
                            onChange={handleRecaptchaChange}
                        />
                    </li>

                    <li><button className="gerenric_button mt_30" onClick={SaveContactForm}>{requestData?.req_access_v2_form_btn} </button> </li>
                    <li><div className="form_term">{requestData?.req_access_v2_form_detail}  <Link to="legal">{requestData?.req_access_v2_form_term_btn}</Link> {selLang == 'fr' ? 'et' : 'and'} <Link to="privacypolicy">{requestData?.req_access_v2_form_privacy_btn}</Link></div></li>
                </ul>
            </div>



        </LoadingOverlay>

    )
}

export default RequestForm
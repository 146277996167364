import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import "./styles/styles.css";
import "./styles/responsive.css";
import "./styles/slidinput.min.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/storeCreator";
// import "react-accessible-accordion/dist/fancy-example.css";
ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  // </React.StrictMode>
  document.getElementById("root")
);

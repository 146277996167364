import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ChangeLang from "./changeLang";
import { useSelector } from "react-redux";
import { langSelector } from "../redux/slices/langSlice";
import useApiClient from "../hooks/useApiClient";
import shapeImg from "../assests/images/menu_shape.svg";
import MobileImg from "../images/mobile_menu_bg.svg";
import parse from "html-react-parser";
import { useLocation } from 'react-router-dom';

const MenuName = () => {
  const [menuData, setMenuData] = useState([]);
  const selLang = useSelector(langSelector);

  const location = useLocation();
  const pathname = location.pathname; // Current page name

  // You can further process or display the `pathname` variable as needed
  console.log("Current Page Name:", pathname);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=18");
    setMenuData(response);
  }, [selLang]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <nav id="navigation_section">
      <ul>
        <li className="menu_mobile_show">
          {menuData?.data && parse(menuData?.data?.header_detail)}
          <p>Ready to invest? <br />Need more information?</p>
          <div className="full_width"><a href="contact.html"><div className="gerenric_button">{menuData.data && menuData?.data?.get_in_touch}</div></a></div>
        </li>
        <li> <NavLink
          activeClassName={`${(pathname == '/' || pathname == '/fr/') ? 'active_menu' : ''}`}
          to={`${selLang == 'fr' ? '/fr/' : '/'}`}

          // "/about"
          onClick={scrollToTop}
        >
          Home
        </NavLink></li>
        <li> <NavLink
          activeClassName={`${pathname.includes('/fr/about') || pathname.includes('/about') ? 'active_menu' : ''}`}
          to={`${selLang == 'fr' ? '/fr/about' : '/about'}`}
          // "/about"
          onClick={scrollToTop}
        >
          {menuData.data && menuData?.data?.menu_about_aydo}
        </NavLink></li>
        <li><NavLink
          activeClassName={`${pathname.includes('/fr/solutions') || pathname.includes('/solutions') ? 'active_menu' : ''}`}
          to={`${selLang == 'fr' ? '/fr/solutions' : '/solutions'}`}
          // "/solutions"
          onClick={scrollToTop}
        >
          {menuData.data && menuData?.data?.menu_our_solutions}
        </NavLink></li>
        <li>
          <NavLink
            activeClassName={`${pathname.includes('/fr/about-shape') || pathname.includes('/about-shape') ? 'active_menu shape_active_menu' : 'shape_active_menu'}`}

            to={`${selLang == 'fr' ? '/fr/about-shape' : '/about-shape'}`}
            // "/shape"
            onClick={scrollToTop}
          >
            <span><div className="shape_menu_text">SHAPE</div></span>
          </NavLink>
        </li>
        <li><NavLink
          activeClassName={`${pathname.includes('/fr/commitments') || pathname.includes('/commitments') ? 'active_menu ' : ''}`}

          to={`${selLang == 'fr' ? '/fr/commitments' : '/commitments'}`}
          //  "/commitments"
          onClick={scrollToTop}
        >
          {menuData.data && menuData?.data?.menu_our_commitments}
        </NavLink></li>
        <li><NavLink
          activeClassName={`${pathname.includes('/fr/career') || pathname.includes('/career') ? 'active_menu ' : ''}`}
          to={`${selLang == 'fr' ? '/fr/career' : '/career'}`}
          //  "/career"
          onClick={scrollToTop}
        >
          {menuData.data && menuData?.data?.menu_join_us}
        </NavLink></li>
        <li> <NavLink
          activeClassName={`${pathname.includes('/fr/contact') || pathname.includes('/contact') ? 'active_menu ' : ''}`}

          to={`${selLang == 'fr' ? '/fr/contact' : '/contact'}`}
          //   "/contact"
          onClick={scrollToTop}
        >
          Contact
        </NavLink></li>
        <li className="menu_mobile_show"> <NavLink
          activeClassName="active_menu"
          to={`${selLang == 'fr' ? '/fr/career' : '/career'}`}
          //  "/career"
          onClick={scrollToTop}
        >
          <div className="nav_join_us_text">{menuData.data && menuData?.data?.want_to_join}</div>
        </NavLink> </li>
        <li className="menu_mobile_show nav_creative_text">© AYDO — Creative Finance</li>
        <li className="menu_mobile_show"><div className="mobile_menu_bg"><img src={MobileImg} alt="" /></div></li>
      </ul>

      {/* <div className="sub-menu">
        <ul>
          {subMenu.map((subm)=>{
            return <li>{subm.name}</li>
          })}
        </ul>
      </div> */}
    </nav>
  );
};

export default MenuName;



const subMenu = [
  /* {id: 0, name: "Our Mission"},
   {id: 1, name: "What We do"},
   {id: 2, name: "Shape"},
   {id: 3, name: "Education"},*/
]
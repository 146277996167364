import React, { useState, memo, useRef, useEffect } from "react";
import BannerArrow from "../../../assests/images/arrow_down2_banner.svg";
import Lottie from "react-lottie-player";
import * as animationDataEN from "./jsons/animation_EN.json";
import * as animationDataFR from "./jsons/animation_FR.json";
import usePersistedState from "../../../hooks/usePersistedState";
import { useSelector } from "react-redux";
import { langSelector } from "../../../redux/slices/langSlice";

const Banner = () => {
  const [show, setShow] = useState(0);
  const selLang = useSelector(langSelector);

  const [animationComplete, setAnimationComplete] = usePersistedState(
    "bannerAnimation",
    false
  );

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setShow(1);
      },
      animationComplete == true || animationComplete == "true" ? 0 : 6000
    );
  });

  return (
    <div>
      <section id="banner_section">
        <Lottie
          loop={false}
          animationData={selLang == "en" ? animationDataEN : animationDataFR}
          play={
            animationComplete == false || animationComplete == "false"
              ? true
              : false
          }
          autoPlay={
            animationComplete == false || animationComplete == "false"
              ? true
              : false
          }
          goTo={
            animationComplete == false || animationComplete == "false" ? 0 : 330
          }
          onComplete={(e) => {
            setAnimationComplete(true);
          }}
        />
        <div className="banner_arrow_btn">
          {show === 1 && (
            <a href="#section_1" className="banner_arrow">
              <img src={BannerArrow} alt="" />
            </a>
          )}
        </div>

        {/* <Lottie options={defaultOptions} /> */}
      </section>
    </div>
  );
};

export default Banner;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { langSelector } from "../../redux/slices/langSlice";
import useApiClient from "../../hooks/useApiClient";
import parse from "html-react-parser";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import LegalContent from "./components/LegalContent";
const Legal = () => {
  return (
    <div>
      <Header />
      <section id="content_section">
        <LegalContent />
      </section>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Legal;

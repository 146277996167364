import React, { useState, useEffect } from "react";
import { langSelector } from "../../../redux/slices/langSlice";
import { addContactEmail, getSaveContectSelector } from '../../../redux/slices/contactSlice'
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Audio } from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";
import parse from "html-react-parser";

const ContactForm = ({ contactFormData }) => {
    const dispatch = useDispatch()
    const selLang = useSelector(langSelector);
    const [recaptchaValue, setRecaptchaValue] = useState("");
    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState(null);
    const [success, setSucces] = useState(false)
    const status = useSelector((state) => state.contactform.status);
    useEffect(() => {
        if (status === 'contact_form_saved') {
            setFName('');
            setLName('');
            setEmail('');
            setMessage('');
            setSucces(true)
        }
    }, [status]);


    const SaveContactForm = () => {
        if (!recaptchaValue) {
            setError("Please complete the reCAPTCHA.");
        } else {
            if (fname && lname && message && email) {
                dispatch(addContactEmail({ fname: fname, lname: lname, email: email, message: message }));
                setError(null);
            } else {
                setError("Please Fill in all fields First");
            }
        }

    };

    return (
        <LoadingOverlay
            tag="div"
            active={status == "saving_contact"}
            styles={{ overlay: (base) => ({ ...base, background: "#fff", opacity: 0.5 }) }}
            spinner={<Audio height="100" width="100" color="#4fa94d" ariaLabel="audio-loading" wrapperStyle={{}} wrapperClass="wrapper-class" visible={true} />}
        >
            <div className="form_box">
                <h2>{contactFormData?.contact_form_title}</h2>
                {success && <div className="error_msg">Email Sent Succesfully</div>}
                {error && <div className="error_msg">{error}</div>}
                <ul>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={contactFormData?.contact_form_fname} value={fname} onChange={(e) => setFName(e.target.value)} /></li>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={contactFormData?.contact_form_lname} value={lname} onChange={(e) => setLName(e.target.value)} /></li>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={contactFormData?.contact_email_lbl} value={email} onChange={(e) => setEmail(e.target.value)} /></li>
                    <li><input type="text" className="gerenric_input myInputCentered" placeholder={contactFormData?.contact_form_message} value={message} onChange={(e) => setMessage(e.target.value)} /></li>
                    <li>
                        <ReCAPTCHA
                            sitekey="6LezCRwoAAAAAA5eg6U_-B664HUNdXFc3Cf4cj8M"
                            onChange={handleRecaptchaChange}
                        />
                    </li>

                    <li><button className="gerenric_button mt_30" onClick={SaveContactForm}>{contactFormData?.contact_form_btn}</button> </li>
                    <li><div className="form_term">
                        {contactFormData && parse(`${contactFormData?.contact_form_detail}`)}   <Link to={selLang == 'fr' ? '/fr/legal' : 'legal'}>{contactFormData?.contact_form_term_btn}</Link> and <Link to={selLang == 'fr' ? '/fr/privacypolicy' : 'privacypolicy'} >{contactFormData?.contact_form_privacy_btn}</Link>

                    </div></li>
                </ul>
            </div>

        </LoadingOverlay>

    )
}

export default ContactForm
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import useApiClient from "../../hooks/useApiClient";
import parse from "html-react-parser";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { GiCheckMark } from "react-icons/gi";
import CookieImg from '../../images/gerenric_content_bg.svg'


const CookiePolicy = () => {
  const dispatch = useDispatch();
  const [cookieData, setCookieData] = useState([]);
  const selLang = useSelector(langSelector);

  const [makeApiCall] = useApiClient();

  useEffect(async () => {
    let response = await makeApiCall("get_site_contents&page_id=29");
    setCookieData(response);
  }, [selLang]);

  return (
    <div>
      <Header />

      <section id="content_section">
        <div className="cookie_page gerenric_padding position_relative">
          <div className="gerenric_content_bg"><img src={CookieImg} alt="" /></div>
          <div className="page_width">

            <div className="gerenric_content">
              <div className="content_block">
                <div className="gerenric_heading"><span className="hdg_border_none">{cookieData?.data && cookieData?.data?.cookieplcy_head}</span></div>
                {cookieData.data &&
                  parse(`${cookieData?.data?.cookieplcy_details}`)}

              </div>
              {/* <div className="content_block">
                <h2>Table of Contents</h2>
                <ol>
                  <li><a href="javascript:void(0)">What is a Cookie?</a></li>
                  <li><a href="javascript:void(0)">Who uses Cookies?</a></li>
                  <li><a href="javascript:void(0)">Why are Cookies used on the Website and how?</a></li>
                  <li><a href="javascript:void(0)">What are the consequences of blocking the Cookies?</a></li>
                  <li><a href="javascript:void(0)">How do I set my Cookies?</a></li>
                </ol>
              </div>
              <div className="content_block">
                <h2>What is a cookie</h2>
                <p><span>Subtitle example</span></p>
                <p>A Cookie, and all similar tracers ("cookies") are tracking files that may be saved on your device (for example, computer, tablet, smartphone) when you use the Website with a browser.</p>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section id="content_section">
        <div className="inner_heading h_mb_80">
          <br />
          <h1> {cookieData?.data && cookieData?.data?.cookieplcy_head} </h1>
          <br />
          <br />
        </div>
        <div className="legal_content">
          <div className="all_content">
            {cookieData.data &&
              parse(`${cookieData?.data?.cookieplcy_details}`)}
            
          </div>
        </div>
      </section> */}
      {/*CONTENT_SECTION_END*/}
      <Footer />
    </div>
  );
};

export default CookiePolicy;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postClient } from "../../api/postClient";

// save contact form
export const addContactEmail = createAsyncThunk("saveContact/addContactEmail", async (params) => {
    const response = await postClient.post(`send_contact`, params);
    return response;
});

// login system
export const addLogin = createAsyncThunk("saveContact/addLogin", async (params) => {
    const response = await postClient.post(`login`, params);
    return response;
});

// login system
export const requestSent = createAsyncThunk("saveContact/requestSent", async (params) => {
    const response = await postClient.post(`send_request_access`, params);
    return response;
});


const initialState = {
    saveContact: {},
    loginData: {},
    requestEmailData: {},
};


const contactSlice = createSlice({
    name: "contactform",
    initialState,
    status: "idle",
    reducers: {
        updateStatus(state, action) {
            state.status = action.payload.status;
        },
    },

    extraReducers: {

        // saving Contact Form

        [addContactEmail.pending]: (state, action) => {
            state.status = "saving_contact";
        },
        [addContactEmail.fulfilled]: (state, action) => {
            if (action.payload.status == "1") {
                state.status = "contact_form_saved";
                if (action.payload.data != null) {
                    state.saveContact = action.payload;
                }
            }
        },
        [addContactEmail.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },

        // login Form

        [addLogin.pending]: (state, action) => {
            state.status = "loging";
        },
        [addLogin.fulfilled]: (state, action) => {
            state.status = "loged_succesfuly";
            state.loginData = action.payload;
        },
        [addLogin.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },

        // Request Email

        [requestSent.pending]: (state, action) => {
            state.status = "sending_email";
        },
        [requestSent.fulfilled]: (state, action) => {
            if (action.payload.status == "1") {
                state.status = "sent_email_succesfuly";
                if (action.payload.data != null) {
                    state.requestEmailData = action.payload;
                }
            }

            state.loginData = action.payload;
        },
        [requestSent.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },
    },
});

export const { updateStatus } = contactSlice.actions;
const contactFormReducer = contactSlice.reducer;
export default contactFormReducer;

export const getSaveContectSelector = (state) => state.contactform.saveContact;
export const getLoginDataSelector = (state) => state.contactform.loginData;